import { Avatar, Button, Divider, Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import AccountContainer from '../../components/Account/AccountContainer'
import { Facebook, LinkedIn, Twitter, YouTube } from '@material-ui/icons';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as ProfileIcon } from '../../assets/images/account/profile.svg'
import { ReactComponent as PaymentsIcon } from '../../assets/images/account/payments.svg'
import { Link } from 'react-router-dom';


const Dashboard = ({ user }) => {
    const [ userData, setUserData ] = useState(user?.userData || {})
    const isTab = useMediaQuery({ query: '(max-width: 1000px)' })

    useEffect(() => {
        setUserData(user?.userData || {})
    }, [user])

    return (
        <AccountContainer title="Dashboard">
            <Grid container alignItems="center" spacing={2} className="no-margin mb-3 card" style={{ width: '100%' }}>
                <Grid item xs={12} md={5} container spacing={3} alignItems="center">
                    <Grid item xs={4}>
                        <Avatar src={userData?.photoURL || ''} style={{ width: '100%', height: 'auto' }} />
                    </Grid>
                    <Grid item container spacing={2} xs={8} className="no-margin">
                        <Grid item xs={12}>
                            <p><b>{ userData?.displayName }</b></p>
                            <p>{ userData?.professionHeadline }</p>
                            <p className='text-capitalize'>User Status: <b>{ userData?.accountStatus }</b></p>
                        </Grid>
                        { !isTab && <><Grid item>
                            <Button color="primary" variant="contained">View Profile</Button>
                        </Grid>
                        <Grid item>
                            <Button color="secondary" variant="contained">Edit Profile</Button>
                        </Grid> </>}
                    </Grid>
                    { isTab && <Grid item container spacing={2} xs={12} className="no-margin">
                        <Grid item xs={6}>
                            <Button fullWidth color="primary" variant="contained">View Profile</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button fullWidth color="secondary" variant="contained">Edit Profile</Button>
                        </Grid>
                    </Grid> }
                </Grid>

                { !isTab && <Grid item xs={12} md={2} container alignItems="center" justifyContent="center" style={{ height: '200px' }}>
                    <Divider orientation="vertical" />
                </Grid> }
                
                <Grid item xs={12} md={5} className="dashboard-info">
                    <p><b>Email: </b> { userData?.email }</p>
                    <p><b>Phone number: </b> { userData?.phoneNumber }</p>
                    <p><b>Nationality: </b> { userData?.nationality }</p>
                    <p><b>Address: </b> { userData?.address }</p>

                    <div className="socials d-flex" style={{ justifyContent: isTab ? 'center' : 'flex-start' }}>
                        <a href="#"><LinkedIn /></a>
                        <a href="#"><Facebook /></a>
                        <a href="#"><Twitter /></a>
                        <a href="#"><YouTube /></a>
                    </div>
                </Grid>
            </Grid>

            <Grid container spacing={3} className="no-margin dashboard">
                <Grid item xs={12} md={6} alignItems="center" className="card" style={{ padding: '30px' }}>
                    <h4 className="mt-0 mb-05">Career Summary</h4>
                    {
                        userData?.careerSummary ?
                        <p className="no-margin ft-15">{userData?.careerSummary}</p>
                        :
                        <Link to='/account/profile/personal-information'><Button>Add Career summary</Button></Link>
                    }
                    
                    <Divider className="mt-1 mb-1" />

                    <h4 className="mt-0 mb-05">Education</h4>
                    {
                        userData?.educationalInformation?.length > 0 ?
                        <p className="no-margin">Lorem ipsum dolor sit amet, consectetur adipiscing elit. At quis lectus pulvinar feugiat sed integer convallis in. Vitae, imperdiet sapien sagittis convallis risus aliquam. Quisque auctor maecenas eget bibendum at. Purus tincidunt non, cursus nam est nibh. Hendrerit nisi ut auctor adipiscing. Luctus urna auctor lectus pellentesque egestas auctor sem suscipit.</p>
                        :
                        <Button>Add Educational Experience</Button>
                    }
                    
                    <Divider className="mt-1 mb-1" />

                    <h4 className="mt-0 mb-05">Skills</h4>
                    {
                        userData?.skills?.length > 0 ?
                        <p className="no-margin">Lorem ipsum dolor sit amet, consectetur adipiscing elit. At quis lectus pulvinar feugiat sed integer convallis in. Vitae, imperdiet sapien sagittis convallis risus aliquam. Quisque auctor maecenas eget bibendum at. Purus tincidunt non, cursus nam est nibh. Hendrerit nisi ut auctor adipiscing. Luctus urna auctor lectus pellentesque egestas auctor sem suscipit.</p>
                        :
                        <Button>Add Skills</Button>
                    }
                </Grid>

                <Grid item xs={12} md={6} container>
                    <Grid item xs={12} spacing={2} container className="no-margin dash-let">
                        <Grid item xs={12} md={6}>
                            <Grid item xs={12} container alignItems="center" className="bg-primary no-margin" style={{ padding: '.5rem', borderRadius: '5px', height: '100%' }} spacing={2}>
                                <Grid item xs={3}><PaymentsIcon fill="#00FF72" style={{ width: '100%', height: 'auto' }} /> </Grid>
                                <Grid item xs={9}>
                                    <h4>Payment Option</h4>
                                    {
                                        userData?.paymentMethod ?
                                        <p className="no-margin">Lorem ipsum dolor sit amet, consectetur adipiscing elit. At quis lectus pulvinar feugiat sed integer convallis in. Vitae, imperdiet sapien sagittis convallis risus aliquam. Quisque auctor maecenas eget bibendum at. Purus tincidunt non, cursus nam est nibh. Hendrerit nisi ut auctor adipiscing. Luctus urna auctor lectus pellentesque egestas auctor sem suscipit.</p>
                                        :
                                        <a variant="text">Add payment method</a>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Grid item xs={12} container alignItems="center" className="bg-primary no-margin" style={{ padding: '.5rem', borderRadius: '5px', height: '100%' }} spacing={2}>
                                <Grid item xs={3}>
                                    <ProfileIcon fill="#00FF72" style={{ width: '100%', height: 'auto' }} />
                                </Grid>
                                <Grid item xs={9}>
                                    <h4>Profile Status</h4>
                                    {
                                        userData?.paymentMethod ?
                                        <p className="no-margin">Lorem ipsum dolor sit amet, consectetur adipiscing elit. At quis lectus pulvinar feugiat sed integer convallis in. Vitae, imperdiet sapien sagittis convallis risus aliquam. Quisque auctor maecenas eget bibendum at. Purus tincidunt non, cursus nam est nibh. Hendrerit nisi ut auctor adipiscing. Luctus urna auctor lectus pellentesque egestas auctor sem suscipit.</p>
                                        :
                                        <a variant="text">Complete profile</a>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className="mt-2 card">
                        <h4 className="mt-0 mb-05">Work Experiences</h4>
                        {
                            userData?.employmentInformation?.length > 0 ?
                            <p className="no-margin">Lorem ipsum dolor sit amet, consectetur adipiscing elit. At quis lectus pulvinar feugiat sed integer convallis in. Vitae, imperdiet sapien sagittis convallis risus aliquam. Quisque auctor maecenas eget bibendum at. Purus tincidunt non, cursus nam est nibh. Hendrerit nisi ut auctor adipiscing. Luctus urna auctor lectus pellentesque egestas auctor sem suscipit.</p>
                            :
                            <Button>Add work experiences</Button>
                        }

                        <Divider className="mt-1 mb-1" />

                        <h4 className="mt-0 mb-05">Referees</h4>
                        {
                            userData?.referees ?
                            <p className="no-margin">Lorem ipsum dolor sit amet, consectetur adipiscing elit. At quis lectus pulvinar feugiat sed integer convallis in. Vitae, imperdiet sapien sagittis convallis risus aliquam. Quisque auctor maecenas eget bibendum at. Purus tincidunt non, cursus nam est nibh. Hendrerit nisi ut auctor adipiscing. Luctus urna auctor lectus pellentesque egestas auctor sem suscipit.</p>
                            :
                            <Button>Add referees</Button>
                        }
                    </Grid>
                </Grid>
            </Grid>

        </AccountContainer>
    )
}

const mapStateToProps = ({ user }) => ({ user })

export default connect(mapStateToProps, null)(Dashboard)