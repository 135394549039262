export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_UPDATE = "USER_UPDATE";

export const SET_TEMPLATES = "SET_TEMPLATES";
export const ADD_TO_CART = "ADD_TO_CART";
export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST";
export const SET_CURRENCY = "SET_CURRENCY";
export const SET_UTILITIES = "SET_UTILITIES";
export const SET_ANALYTICS = "SET_ANALYTICS";
export const SET_ORDERS = "SET_ORDERS";