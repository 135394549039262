import { Button, CircularProgress, Dialog, DialogActions, DialogContent, FormControlLabel, Grid, IconButton, TextField, Checkbox ,
    FormControl, FormLabel, Table, TableHead, TableRow, TableCell, TableBody
} from '@material-ui/core'
import { Delete, Add, ZoomInOutlined, Edit, EditOutlined, Refresh } from '@material-ui/icons'
import React, { useEffect, useState, useRef } from 'react'
import AdminContainer from '../../components/AdminContainer'
import * as templateApi from '../../redux/api/template'
import { getPrice } from '../../util'
import { fetchTemplates } from '../../redux/actions/serviceActions'
import { useMediaQuery } from 'react-responsive';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment';

const Resumes = ({ fetchTemplates, services }) => {
    const sm = useMediaQuery({ query: '(min-width: 500px)' })
    const [ utilities, setUtilities ] = useState({})
    const [ openDialog, setOpenDialog ] = useState(false)
    const imageRef = useRef(null)

    const [ openDeleteDialog, setOpenDeleteDialog ] = useState(false)
    const [ deleting, setDeleting ] = useState(false)
    const [ deleteError, setDeleteError ] = useState('')
    const [ sortValue, setSortValue ] = useState('created')

    const [ fetchingTemplates, setFetchingTemplates ] = useState(false)
    const [ templates, setTemplates ] = useState([])

    const [ addingTemplate, setAddingTemplate ] = useState(false)
    const [ editing, setEditing ] = useState(false)
    const [ templateError, setTemplateError ] = useState('')

    const defaultTemplate = {         
        image: "",
        title: "",
        desc: "",
        slug: "",
        price: 4500,
        sales: 2000,
        requiredFields: [ "displayName", "lastName", "firstName", "phoneNumber", "address", "careerSummary", "professionHeadline",
            "employmentInformation", "educationalInformation", "photoURL", "email"
        ]
    }
    const [ templateForm, setTemplateForm ] = useState(defaultTemplate)
 
    const handleFormChange = ({ name, value }) => {
        setTemplateForm({ ...templateForm, [name] : value })
    }

    const refresh = async () => {
        try {
            setFetchingTemplates(true)
            await fetchTemplates();
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setFetchingTemplates(false)
        }
    }

    const addTemplate = async (update = false) => {
        try {
            setAddingTemplate(true)
            const { price, title, image, requiredFields, id = '' } = templateForm
            if(price && image !== '' && title && requiredFields.length > 0) {
                if(update && id){
                    await templateApi.updateTemplate(templateForm, id);
                    alert('Template Updated successfully')
                } else { 
                    await templateApi.addTemplate(templateForm);
                    alert('Template Added successfully')
                }
                setOpenDialog(false)
                setTemplateForm(defaultTemplate)
                refresh()
            } else {
                setTemplateError('All fields are required')
            }
        }
        catch (err) {
            console.log(err)
            setTemplateError(err?.message || "An error occurred, kindly try again")
        } finally {
            setAddingTemplate(false)
        }
    }

    const editTemplate = (template) => {
        setEditing(true)
        setTemplateForm({ ...template })
        setOpenDialog(true)
    }

    const deleteTemplate = async () => {
        try {
            setDeleting(true)
            const { id = '' } = templateForm
            if(id) {
                await templateApi.deleteTemplate(id);
                alert('Template Deleted successfully')
                setOpenDeleteDialog(false)
                setOpenDialog(false)
                refresh()
            } else {
                setDeleteError('No template to delete')
            }
        }
        catch (err) {
            console.log(err)
            setDeleteError(err?.message || "An error occurred, kindly try again")
        } finally {
            setDeleting(false)
        }
    }

    const reset = () => {
        setEditing(false)
        setTemplateForm(defaultTemplate)
        setOpenDialog(false)
        setTemplateError('')
        setAddingTemplate(false)
    }

    const setField = (id) => {
        let fields =  [ ...templateForm.requiredFields ]
        if(fields.includes(id)) {
            handleFormChange({ name: 'requiredFields', value: fields.filter(d => d !== id) })
        }
        else {
            fields.push(id);
            handleFormChange({ name: 'requiredFields', value: fields })
        }
    }


    useEffect(() => {
        setTemplates(services?.templates)
        setUtilities(services?.utilities)
    }, [services?.templates])

    const handleUpload = (file) => {
        if (file.files.length > 0) {
            handleFormChange({ name: 'image', value: file.files[0]})
        }
    }

    return (
        <AdminContainer title={`All Templates (${templates.length})`} processing={fetchingTemplates}>
            <Grid container spacing={3}>
                <Grid item xs={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <Button fullWidth={!Boolean(sm)} style={{ marginTop: sm ? '-3.5rem' : 0, height: '40px' }} 
                        variant="outlined" color="primary" onClick={() => refresh()}
                        startIcon={<Refresh />}
                    >
                        Refresh
                    </Button>
                    <Button fullWidth={!Boolean(sm)} style={{ marginTop: sm ? '-3.5rem' : 0, height: '40px' }} 
                        variant="contained" color="primary" onClick={() => setOpenDialog(true)} className="ml-1"
                        startIcon={<Add />}
                    >
                        Add Template
                    </Button>
                </Grid>

                <Table>
                    <TableHead>
                        <TableRow style={{ fontWeight: 'bold' }}>
                            <TableCell>S/N</TableCell>
                            <TableCell>Image</TableCell>
                            <TableCell>Title</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Sales</TableCell>
                            <TableCell>Creation Date</TableCell>
                            <TableCell>Orders</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            templates?.sort((a, b) => (a[sortValue] > b[sortValue]) ? -1 : 1)?.map((template, key) => (
                                <TableRow key={key}>
                                    <TableCell>{key + 1}</TableCell>
                                    <TableCell>
                                        <img 
                                            width="100px"
                                            src={(template?.image) ? typeof(template?.image) === 'string' ? template?.image : URL.createObjectURL(template?.image) : ''} 
                                        />
                                    </TableCell>
                                    <TableCell>{template.title}</TableCell>
                                    <TableCell>{getPrice(template.price)}</TableCell>
                                    <TableCell>{getPrice(template.sales)}</TableCell>
                                    <TableCell>{moment(template?.created).format('DD/MM/YYYY')}</TableCell>
                                    <TableCell>{template?.orders || 0}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => editTemplate(template)}><EditOutlined /></IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </Grid>

            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}  maxWidth="lg" disableEscapeKeyDown disableBackdropClick>
                <DialogContent>
                    <Grid container justifyContent='space-between' alignItems='center'>
                        <Grid item>
                            <h1 className="mt-05 mb-0">{templateForm?.title ? 'Edit' : 'New'} Template</h1>
                            <p className='text-error mt-0 mb-05'><small>{templateError}</small></p>
                        </Grid>
                        <Grid item>
                            { editing && <IconButton variant="contained" color="secondary" onClick={() => setOpenDeleteDialog(true)}>
                                <Delete style={{ color: 'brown' }} />
                            </IconButton> }
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} className="template">
                            <input type='file' hidden ref={imageRef} onChange={({target}) => handleUpload(target)} accept="image/*" />
                            <img src={(templateForm?.image) ? typeof(templateForm?.image) === 'string' ? templateForm?.image : URL.createObjectURL(templateForm?.image) : ''} 
                                onClick={() => imageRef.current.click()}
                            />
                        </Grid>
                        <Grid item xs={12} className="template-form">
                            <TextField variant="outlined" fullWidth label="Title" placeholder="Enter template title" 
                                onChange={(e) => handleFormChange(e.target)} value={templateForm.title} name="title"
                            />
                            <TextField variant="outlined" fullWidth label="Description" placeholder="Enter template description"
                                onChange={(e) => handleFormChange(e.target)} value={templateForm.desc} name="desc"
                                multiline
                            />
                            <TextField variant="outlined" type="number" fullWidth label="Price (₦)" placeholder="Enter template price"
                                onChange={(e) => handleFormChange(e.target)} value={templateForm.price} name="price"
                            />
                            <TextField variant="outlined" type="number" fullWidth label="Sales Price (₦)" placeholder="Enter template sales price"
                                onChange={(e) => handleFormChange(e.target)} value={templateForm.sales} name="sales"
                            />
                            <Grid>
                            <FormControl variant='outlined' fullWidth>
                                <FormLabel>Required Fields</FormLabel>
                                {
                                    utilities?.fields?.map(({ name, id }) => (
                                        <FormControlLabel
                                            label={name}
                                            control={<Checkbox checked={Boolean(templateForm?.requiredFields?.includes(id))} 
                                                onChange={() => setField(id)} />}
                                        />
                                    ))
                                }
                            </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="secondary" onClick={() => reset()}>Cancel</Button>
                    <Button variant="contained" color="primary" onClick={() => addTemplate(editing)}>
                        { addingTemplate ? <CircularProgress color='inherit' size={15} /> : editing ? "Update" : "Add" }</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}  maxWidth="sm" disableEscapeKeyDown disableBackdropClick>
                <DialogContent>
                    <Grid item>
                        <h3 className="mt-05 mb-0">Delete Resume Template?</h3>
                        <p className='mt-0 mb-05'><small>Click delete to confirm the deletion of this template</small></p>
                        <p className='text-error mt-0 mb-05'><small>{deleteError}</small></p>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="secondary" onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
                    <Button variant="contained" className='bg-error' onClick={() => deleteTemplate()}>
                        { deleting ? <CircularProgress color='inherit' size={15} /> : "Delete" }</Button>
                </DialogActions>
            </Dialog>
        </AdminContainer>
    )
}

const mapStateToProps = ({ services }) => ({ services })

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ fetchTemplates }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(Resumes);
