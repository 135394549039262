import { Button, CircularProgress, Grid, IconButton, InputAdornment, Tab, Tabs, TextField } from '@mui/material';
import { KeyboardBackspace, VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import React, { useState, useEffect } from 'react'
import PageContainer from '../components/PageContainer'
import { Link, useHistory } from 'react-router-dom';
import {  ReactComponent as GoogleIcon } from '../assets/images/google-icon.svg'
import { signInUser } from '../redux/actions/userActions';
import { fetchUtilities, fetchAnalytics, fetchOrders, fetchTemplates } from '../redux/actions/serviceActions';
import authApi from '../redux/api/auth';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { emailJS, emailJSUser } from '../redux/api/config'

const Auth = ({ signInUser, fetchUtilities, fetchAnalytics, fetchOrders, fetchTemplates }) => {
    const history = useHistory();
    const [section, setSection ] = useState('sign-in')
    const [ activeTab, setActiveTab ] = useState(0)
    const [ processing, setProcessing ] = useState(null)
    const [ showPassword, setShowPassword ] = useState(false)
    const [ errors, setErrors ] = useState({})
    const [ signInForm, setSignInForm ] = useState({ email: '', password: '' })
    const [ signUpForm, setSignUpForm ] = useState({ email: '', password: '', displayName: '' })
    const [ emailReset, setEmailReset ] = useState('')

    const navigate = (num) => {
        setErrors({})
        setSection(num === 0 ? 'sign-in' : num === 1 ? 'sign-up' : 'forgot-password' )
        setActiveTab(num)
    }

    useEffect(() => {
        const path = history.location.pathname.split('/')[1]
        setSection(path)
        setActiveTab(path === 'sign-in' ? 0 : path === 'sign-up' ? 1 : 2 )
    }, [history.location])

    const handleForm = ({ name, value }, type) => {
        Boolean(type === 'signIn') ? setSignInForm({ ...signInForm, [name]: value }) : setSignUpForm({ ...signUpForm, [name]: value })
    }

    const signIn = async (google = false) => {
        try {
            setProcessing(google ? 4 : 1)
            const { signUp = null, userData } = await signInUser(signInForm, google);
            if(signUp) {
                await emailJS.send('retro_support', 'contact_form', {
                    to_name: signUp,
                    to_email: signInForm.email
                }, emailJSUser)
            }

            if(history?.location?.state?.redirect) {
                history.push(history?.location?.state?.redirect)
            } else if (userData?.admin) {
                await fetchUtilities();
                await fetchAnalytics();
                await fetchOrders();
                await fetchTemplates();
                history.push('/admin/dashboard')
            } else {
                history.push('/account')
            }
        }
        catch (err) {
            setErrors({ message: err?.code || err?.message || "An error occurred"});
        }
        finally {
            setProcessing(null);
        }
    }

    const signUp = async () => {
        try {
            setProcessing(2)
            await authApi.signUp(signUpForm)
            await emailJS.send('retro_noreply', 'account_creation', {
                to_name: signUpForm.displayName,
                to_email: signUpForm.email
            }, emailJSUser)
            await signInUser(signUpForm, false);
            if(history?.location?.state?.redirect) {
                history.push(history?.location?.state?.redirect)
            } else {
                history.push('/account')
            }
        }
        catch (err) {
            console.log(err)
            setErrors({ message: err?.code || err?.message || "An error occurred"});
        }
        finally {
            setProcessing(null);
        }
    }

    const resetPassword = async (e) => {
        e.preventDefault()
        try {
            setProcessing(3)
            await authApi.resetPassword(emailReset)
        }
        catch (err) {
            console.log(err)
            setErrors({ message: err?.code || "An error occurred"});
        }
        finally {
            setProcessing(null);
        }
    }

    return (
        <PageContainer noHeader title="Login">
            <Grid container className="login">
                <Grid item xs={12} md={5} className="bg" alignContent="center" container alignItems="center">
                    <h1>Welcome back to RetroResume</h1>
                    <p>We are happy to see you again. <br />Please log in to your account to continue</p>
                    <span>Excellence is to do a common thing in an uncommon way. Let’s create that uncommon resume for you to get the job you seek.</span>

                    <Link to="/"><Button variant="text" color="inherit" startIcon={<KeyboardBackspace />}>Go Back Home</Button></Link>
                </Grid>
                <Grid item xs={12} md={7} className="bg2" container alignItems="center">
                    <div className="form">
                        { 
                            section !== "forgot-password" &&
                            <Tabs indicatorColor="primary" value={activeTab} onChange={(e, v) => navigate(v)}>
                                <Tab label="Sign In" />
                                <Tab label="Sign Up" />
                            </Tabs>
                        }
                        {
                            errors?.message &&
                            <p className="text-center mt-0 text-error"><small>{errors?.message}</small></p>
                                
                        }
                        {
                            section === 'sign-in' ?
                            <form onSubmit={(e) => { e.preventDefault(); signIn() }}>
                                <TextField fullWidth variant="outlined" label="Your Email" placeholder="Enter email address" InputLabelProps={{ shrink: true }}
                                    name="email" value={signInForm.email} onChange={(e) => handleForm(e.target, 'signIn')}
                                />
                                
                                <TextField fullWidth variant="outlined" label="Your Password" placeholder="Enter password" InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => setShowPassword(!showPassword)}
                                                    edge="end" size='small'
                                                >
                                                    {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    name="password" value={signInForm.password} onChange={(e) => handleForm(e.target, 'signIn')} type={showPassword ? 'text' : 'password'}
                                />

                                <p className="text-right mt-0 text-primary"><Link to="/forgot-password" onClick={() => setErrors({})}><small>Forgot Password?</small></Link></p>
                                
                                <Button type="submit" variant="contained" color="secondary" fullWidth >
                                    { processing === 1 ? <CircularProgress color="inherit" size={15} /> : "Login"}
                                </Button>

                                <p style={{ textAlign: 'center', fontWeight: 600 }}>OR</p>

                                <Button onClick={() => signIn(true)} startIcon={processing !== 4 && <GoogleIcon />} color="secondary" variant="outlined" style={{ textTransform: 'inherit' }} fullWidth>
                                    { processing === 4 ? <CircularProgress size={15} color="inherit" /> : 'Sign In with Google' }    
                                </Button>
                            </form>
                            :
                            section === 'sign-up' ?
                            <form onSubmit={(e) => { e.preventDefault(); signUp(); }}>
                                <TextField fullWidth variant="outlined" label="Your Display Name" placeholder="Enter display name" InputLabelProps={{ shrink: true }}
                                    name="displayName" value={signUpForm.displayName} onChange={(e) => handleForm(e.target, 'signUp')}
                                />

                                <TextField fullWidth variant="outlined" label="Your Email" placeholder="Enter email address" InputLabelProps={{ shrink: true }}
                                    name="email" value={signUpForm.email} onChange={(e) => handleForm(e.target, 'signUp')}
                                />
                                
                                <TextField fullWidth variant="outlined" label="Your Password" placeholder="Enter password" InputLabelProps={{ shrink: true }}
                                    name="password" value={signUpForm.password} onChange={(e) => handleForm(e.target, 'signUp')}
                                />
                                
                                <Button type="submit" variant="contained" color="secondary" fullWidth >                                
                                    { processing === 2 ? <CircularProgress color="inherit" size={15} /> : "Sign Up"}
                                </Button>

                                <p style={{ textAlign: 'center', fontWeight: 600 }}>OR</p>

                                <Button onClick={() => signIn(true)} startIcon={processing !== 4 && <GoogleIcon />} color="secondary" variant="outlined" style={{ textTransform: 'inherit' }} fullWidth>
                                    { processing === 4 ? <CircularProgress size={15} color="inherit" /> : 'Sign Up with Google' }
                                    
                                </Button>
                            </form>
                            :
                            <>
                                <h2 className="text-center">Password Reset</h2>

                                <form onSubmit={(e) => resetPassword(e)}>
                                    <TextField fullWidth variant="outlined" label="Your Email" placeholder="Enter email address" InputLabelProps={{ shrink: true }} 
                                        value={emailReset} onChange={(e) => setEmailReset(e.target.value)}
                                    />
                                    
                                    <Button variant="contained" color="secondary" type="submit" fullWidth >
                                        { processing === 3 ? <CircularProgress size={15} color="inherit" /> : 'Reset Password' }
                                    </Button>
                                    
                                    <p className="text-center mt-1 text-primary"><Link onClick={() => setErrors({})} to="/sign-in"><small>Remember Password? Login</small></Link></p>
                                </form>
                            </>
                        }
                    </div>
                </Grid>
            </Grid>
        </PageContainer>
    )
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ signInUser, fetchUtilities, fetchAnalytics, fetchOrders, fetchTemplates }, dispatch)
}

export default  connect(null, mapDispatchToProps)(Auth);
