export const PRIMARY_COLOR = "#0E4DA4"
export const PRIMARY_LIGHT_COLOR = "#AFD1FF"
export const SECONDARY_COLOR = "#0E0E0E"

export const SUCCESS_COLOR = "#27AE60"
export const WARNING_COLOR = "#F2C94C"
export const ERROR_COLOR = "#F74343"

export const BLACK_COLOR = "#000000"
export const GREY_COLOR = "#ACACAC"
export const WHITE_COLOR = "#FFFFFF"


export const TEMPLATE_COLOR_1 = '#69959C'
export const TEMPLATE_COLOR_2 = '#D5973B'
export const TEMPLATE_COLOR_3 = '#0266C3'
export const TEMPLATE_COLOR_4 = '#E46000'
export const TEMPLATE_COLOR_5 = '#3FD24E'