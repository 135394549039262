import React from 'react'

const ColorCircle = ({ color, active = false, setActiveColor }) => {
    return (
        <div 
            onClick={() => setActiveColor(color)} 
            className={active ? 'color-circle-holder active' : 'color-circle-holder'}
            style={{ borderColor: color }}
        >
            <div className='color-circle' style={{ backgroundColor: color }}></div>
        </div>
    )
}

export default ColorCircle
