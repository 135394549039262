import { Button, Grid, IconButton } from '@material-ui/core';
import { Favorite, ShoppingCart, FavoriteBorder, AddShoppingCart } from '@material-ui/icons';
import React, { useState, useEffect } from 'react'
import { baseUrl } from '../redux/api/config';
import { TEMPLATE_COLOR_1, TEMPLATE_COLOR_2, TEMPLATE_COLOR_3, TEMPLATE_COLOR_4, TEMPLATE_COLOR_5 } from '../styles/colors';
import { getPrice, toast } from '../util';
import ColorCircle from './ColorCircle';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { handleCart, handleWishList } from '../redux/actions/serviceActions'
import { Link } from 'react-router-dom';

const Template = ({ template, handleCart, generate = false, handleWishList, services }) => {
    const defaultColors = [TEMPLATE_COLOR_1, TEMPLATE_COLOR_2, TEMPLATE_COLOR_3, TEMPLATE_COLOR_4, TEMPLATE_COLOR_5];
    const [ activeColor, setActiveColor ] = useState(TEMPLATE_COLOR_1)

    const [cartList, setCartList ] = useState(services?.cartList || [])
    const [ wishList, setWishList ] = useState(services?.wishList || [])

    const addCart = async () => {
        if(cartList.filter(({ id }) => id === template.id).length === 0) {
            await handleCart(template, 'add')
            toast('Template added to cart')
        } else {
            toast('Template is already in cart')
        }
    }

    const addWishList = async () => {
        if(wishList.filter(({ id }) => id === template.id).length === 0) {
            await handleWishList(template, 'add')
            toast('Template added to Wishlist')
        } else {
            toast('Template is already in Wishlist')
        }
    }


    useEffect(() => {
        setCartList(services?.cartList || [])
        setWishList(services?.wishList || [])
    }, [services])

    return (
        <Grid container className='template-card' >
            <Grid item xs={12} className='img-holder'>
                <img src={template.image} />
            </Grid>
            <Grid item xs={12} container alignItems='center' className='mt-05 mb-0'>
                <Grid item xs={12}>
                    <h4 className='text-center template-price'>{getPrice(template?.price, template?.sales)}</h4>
                </Grid>
                {/* <Grid item xs={6} className='color-circle-container'>
                    {
                        defaultColors.map((color, key) => (
                            <ColorCircle setActiveColor={setActiveColor} color={color} active={Boolean(activeColor === color)} key={key} />
                        ))
                    }
                </Grid> */}
            </Grid>
            
            <Grid item xs={12}>
                <p className='template-title mt-0'>{template?.title || ''}</p>
            </Grid>

            <Grid item xs={12} spacing={1} container>
                <Grid item xs={12}>
                    {
                        Boolean(generate) ?
                        <Button fullWidth variant='contained' color='secondary'>Generate Resume</Button>
                        :
                        <Link to={`/template/${template.slug}`}>
                            <Button fullWidth variant='contained' color='secondary'>Get Resume</Button>
                        </Link>
                    }
                </Grid>
                {/* {
                    Boolean(!generate) &&
                    <>
                        <Grid item xs={2}>
                            <IconButton color='secondary' onClick={() => addCart()}>
                                {
                                    Boolean(cartList.filter(({ id }) => id === template.id).length === 0) ?
                                    <AddShoppingCart />
                                    :
                                    <ShoppingCart />
                                }
                            </IconButton>
                        </Grid>
                        <Grid item xs={2}>
                            <IconButton color='secondary' onClick={() => addWishList()}>
                                {
                                    Boolean(wishList.filter(({ id }) => id === template.id).length === 0) ?
                                    <FavoriteBorder />
                                    :
                                    <Favorite />        
                                }
                            </IconButton>
                        </Grid>
                    </>
                } */}
            </Grid>
        </Grid>
    )
}

const mapStateToProps = ({ services }) => ({ services })

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ handleCart, handleWishList }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Template)
