import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import Routes from './routes'
import Theme from './styles/theme';
import Theme2 from './styles/theme2';
import { Provider } from "react-redux";
import initStore from "./redux/store";
import { ToastContainer } from 'react-toastify';
import ComingSoon from './components/ComingSoon';
import 'tiny-slider/dist/tiny-slider.css';

export const store = initStore({});

const App = () => {

  return (
    <Provider store={store}>
        <Theme>
            <Theme2>
                {
                    // !(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ?
                    // <ComingSoon />
                    // :
                    <>
                        <Routes />
                        <ToastContainer />
                    </>
                }
            </Theme2>
        </Theme>
    </Provider>
  );
}

export default App;
