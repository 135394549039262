import { Container, Grid } from '@material-ui/core';
import React from 'react'
import PageContainer from '../components/PageContainer';
import { useMediaQuery } from 'react-responsive';
import AboutImg from '../assets/images/about.svg'
import vector from '../assets/images/vector.png'
import vector2 from '../assets/images/vector2.png'
import vector3 from '../assets/images/vector3.png'
import vector4 from '../assets/images/vector4.png'

import about2 from '../assets/images/about2.svg'
import about3 from '../assets/images/about3.svg'
import about4 from '../assets/images/about4.svg'

import mission from '../assets/images/mission.svg'
import vision from '../assets/images/vision.svg'
import goal from '../assets/images/goal.svg'

import dots from '../assets/images/dots.png'

const About = () => {
    const isTab = useMediaQuery({ query: '(max-width: 1000px)' })
    return (
        <PageContainer title="About Us">
            <div className="banner about">
                <Container className="container-padding">
                    <Grid container style={{ position: 'relative', zIndex: 1 }}>
                        <Grid container direction="column" item xs={12} md={6} style={{ margin: isTab ? '30% 0 0' : 0 }}>
                            <h1>About Us</h1>
                            <p>
                                At RetroResume, we’re cracking the code for writing perfect resumes. Plus, we’re giving you a state-of-the art design to create a beautiful, professional job application resume.
                                <br/>
                                Whether this is your first job or you’re looking for a promotion, we will help you stand above the rest by highlighting your personality and achievements.
                            </p>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <img src={AboutImg} alt="about" className="section-image"/>
                        </Grid>
                    </Grid>
                    <div>
                        <img alt="vector" src={vector2} className="ball" style={{ left: '5%', top: isTab ? '10%' : '25%' }} />
                        <img alt="vector" src={vector4} className="ball" style={{ left: isTab ? '75%' : '44%', top: isTab ? '18%' : '15%' }} />
                        <img alt="vector" src={vector3} className="ball" style={{ left: isTab ? '10%' : '15%', bottom: isTab ? '40%' : '10%', width: isTab ? '150px' : 'auto' }} />
                        <img alt="vector" src={vector} className="ball" style={{ right: '5%', bottom: '20%', display: isTab ? 'none' : 'initial' }} />
                    </div>
                </Container>
            </div>
            
            <Container className="container-padding">
                <Grid container className="mt-4 section" alignItems="center" direction={isTab ? 'column-reverse': 'row'}>
                    <Grid item xs={12} md={6} container justifyContent="center">
                        <img src={about2} alt="about1" className="section-image" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className="title">
                            <p>our <span>story</span></p>
                            <h2>How it all started</h2>
                        </div>
                        <p className="mt-0">RetroResume is set out on a mission to change the HR landscape forever. Now, 100+ resume templates, we strive to provide creative resumes for our customers to ensure the employer wants you even before the interview. It took the combining skills of great content writing, creative and savvy designing.</p>
                    </Grid>
                </Grid>
                <div>
                    <img alt="vector" src={vector} className="ball" style={{ right: '5%', top: '145%' }} />
                </div>
                <Grid container className="mt-4 section" justifyContent="center" spacing={3}>
                    <Grid container item xs={12} md={4}>
                        <Grid container direction="row" className="icon-text">
                            <img src={mission} alt="about1" />
                            <h3>Our Mission</h3>
                        </Grid>
                        <p className="mt-0">Our mission is to follow all major trends and share up-to-date tips and insights with you so that you’ll always come out on top in the recruitment game.</p>
                    </Grid>
                    <Grid container item xs={12} md={4}>
                        <Grid container direction="row" className="icon-text">
                            <img src={vision} alt="about1" />
                            <h3>Our Vision</h3>
                        </Grid>
                        <p className="mt-0">Our vision is to be the number one community providing excellent, amazing, creative and state of the art resumes for job seekers and also provide major trends, tips and insights to ensure that our customers are always top in the recruitment game.</p>
                    </Grid>
                    <Grid container item xs={12} md={4}>
                        <Grid container direction="row" className="icon-text">
                            <img src={goal} alt="about1" />
                            <h3>Our Goal</h3>
                        </Grid>
                        <p className="mt-0">Our Goal is to build a home for job seekers to be able to continuously improve themselves and their online professional image to employer.</p>
                    </Grid>
                </Grid>
                <div>
                    { !isTab && <img alt="vector" src={vector} className="ball" style={{ left: '20%', top: '180%' }} /> }
                </div>
                <Grid container className="mt-7 section" alignItems="center">
                    <Grid item xs={12} md={6} container justifyContent="center">
                        <img src={about3} alt="about3" className="section-image" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className="title">
                            <p>Retro<span>Resume</span></p>
                            <h2>An ultimate stop shop for job seekers</h2>
                        </div>
                        <p className="mt-0">Thinking of ways to make your resume creative and attractive, well you have found the right community. We can bring your conceptual designs to live also. We have amazing templates and we will continuously provide more templates to ensure our customers satisfaction.</p>
                    </Grid>
                </Grid>
                <div>
                    <img alt="vector" src={dots} className="ball" style={{ left: isTab ? '7%' : '15%', top: isTab ? '320%' : '225%', width: isTab ? '100px' : 'auto' }} />

                    { !isTab && <img alt="vector" width="30" src={vector} className="ball" style={{ right: '10%', top: '215%' }} /> }
                </div>
                <Grid container className="mt-6 mb-7 section" alignItems="center">
                    <Grid item xs={12} md={6}>
                        <div className="title">
                            <p>Let’s FIX youR <span>cAREER</span></p>
                            <h2>RetroResume is the ultimate career toolbox</h2>
                        </div>
                        <p className="mt-0">RetroResume has helped job seekers worldwide find employment. With a rapidly growing community of job seekers with success stories a year, RetroResume is arguably a name to remember amongst the world’s fastest growing resume builder’s website. <br />
                        Get the job now and thank us later.
                        </p>
                    </Grid>
                    <Grid item xs={12} md={6} container justifyContent="center">
                        <img src={about4} alt="about4" className="section-image"  />
                    </Grid>
                </Grid>
                <div>
                    <img alt="vector" width="100" src={vector3} className="ball" style={{ left: isTab ? '70%' : '20%', top: isTab ? '385%' : '293%' }} />

                    <img alt="vector" src={dots} className="ball" style={{ right: isTab ? '7%' : '13%', top: isTab ? '480%' : '275%', width: isTab ? '100px' : 'auto' }} />
                </div>
            </Container>
        </PageContainer>
    )
}

export default About
