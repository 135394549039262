import { Button, Container, Grid } from '@material-ui/core';
import { KeyboardBackspace } from '@material-ui/icons';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PageContainer from '../components/PageContainer';
import { fetchTemplates } from '../redux/actions/serviceActions'
import Template from '../components/Template';
import { useMediaQuery } from 'react-responsive';
import TestimonyImage from '../assets/images/testimony.png'
import dots from '../assets/images/dots.png'


const Templates = ({ services, fetchTemplates }) => {
    const isTab = useMediaQuery({ query: '(max-width: 1000px)' })
    const [ templates, setTemplates ] = useState(services?.templates || [])
    const [ fetching, setFetching ] = useState(false)

    const [ search, setSearch ] = useState([])

    const fetchCategories = async () => {
        setFetching(true)
        try {
            // const res = await fetchTemplates();
        } catch (e) {
            console.log(e);
        } finally {
            setFetching(false)
        }
    }

    useEffect(() => {
        fetchCategories();
    }, [])

    useEffect(() => {
        setTemplates(services?.templates || [])
    }, [services.templates])

    useEffect(() => {
        fetchMoreData(10)
    }, [templates])

    const fetchMoreData = () => {
        setSearch(templates.slice(0, search.length + 12))
    }

    return (
        <PageContainer title="Resume Templates" processing={fetching}>
            <div className="banner categories text-center" style={{ minHeight: isTab ? '100vh' : '85vh' }}>
                <Container className="container-padding">
                    <Grid container style={{ position: 'relative', zIndex: 1 }}>
                        <Grid container direction="column" item xs={12} alignItems="center" style={{ margin: 0 }}>
                            <h1>Get the perfect resume from our list of perfectly prepared resume.</h1>
                            <p>Each resume template is expertly designed and follows the exact “resume rules” hiring managers look for</p>
                            <Button variant="contained" color="primary">Create my resume</Button>
                        </Grid>
                    </Grid>

                </Container>
            </div>
            <Container className="container-padding">

                <Grid container spacing={3} className='mt-2 mb-2' justifyContent='center'>
                    {
                        search?.sort((a, b) => (a?.orders || 0 > b?.orders || 0) ? -1 : 1)?.map((template, key) => (
                            <Grid key={key} item xs={12} sm={6} md={4} lg={3}>
                                <Template template={template} />
                            </Grid>
                        ))
                    }
                    { search.length > 0 && search.length !== templates.length && <Grid item xs={12} container justifyContent='center'>
                        <Button variant='contained' color='primary' onClick={fetchMoreData} style={{ margin: '1rem auto' }}>
                            Load More
                        </Button>
                    </Grid>}
                </Grid>
            </Container>

        </PageContainer>
    )
}

const mapStateToProps = ({ services }) => ({ services });

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ fetchTemplates }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Templates)
