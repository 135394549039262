import React from 'react'
import Header from './Header'
import Footer from './Footer'
import Helmet from 'react-helmet' 

const PageContainer = ({ children, noHeader, title = '', processing = false, secondaryLoader = false }) => {
    return (
        <div className="d-flex f-column" style={{ minHeight: '100vh' }}>
            <Helmet>
                <title>{title && `${title} | `}Retro Resume</title>
            </Helmet>
            { !noHeader && <Header processing={ processing } secondaryLoader={secondaryLoader} /> }
            <div style={{ flex: 1 }}>
                {children}
            </div>
            <Footer />
        </div>
    )
}

export default PageContainer
