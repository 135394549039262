import { Container, Grid } from '@material-ui/core';
import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive';
import PageContainer from '../components/PageContainer';
import AccordionComponent from '../components/Accordion';

const PrivacyPolicy = () => {
    const isTab = useMediaQuery({ query: '(max-width: 1000px)' })

    const policies = [
        {
            title: 'Scope and Consent',
            body: `
                Provider is an online destination that provides resume and cover letter building, resume and cover letter storage, and other services for individuals seeking to further their career. We do not guarantee employment for any user of the Site. When you use the Site, you are contracting with Provider. By accessing the Site, and the services provided in it or through any of our family of websites that link to this Privacy Policy, including, but not limited to, noukaan.com, and any other Site powered by noukaan.com, you are accessing a Provider offering.
            `
        },
        {
            title: 'Information We Collect About You',
            body: `
                Information we collect about you depends on how you use our Site.
                <br />
                <b>(a) Registration, Services and Account Information.</b> You can browse the Site without registering for an account with us in that case we may collect information regarding your device, geolocation data, behavioral, target job and target profile. When you register an account or use certain of our services (including, but not limited to, Site email account(s), Resume Builder, Resume Services, Search Jobs), create a profile, create a resume, search for or purchase services, or otherwise engage with our Site, you must provide certain information such as a valid email address, otherwise, you will not be able to create an account. You must also provide payment information such as credit or debit card information, name and billing address in order to purchase services. This information is only associated with your account after you have signed in as a registered user. We will also collect other data you voluntarily provide, such as name, surname, contact data, and information you enter on the Site, when creating documents (for example employment status and education).
                <br />
                <b>(b) Information to Respond to Your Requests.</b> You may give us contact information (for example, your email address) so we can respond to your questions and requests, as well as responding to your requests to send you newsletters and other communications.
                <br/ >
                <b>(c)Third party log-in.</b> We may collect some basic information when you access the Site with third-party login credentials such as your name, user name, and your email address.    
            `
        },
        {
            title: 'Uses, Retention and Storage',
            body: `
                We process your information for the following purposes and legal bases:
                <br />
                <b>(a) Operate our Site and Provide You with Our Services.</b> We use the Information we collect to (i) respond to your questions and requests; (ii) provide customer service (e.g., to fulfill product and service orders made by you, give you information about your account to resolve disputes, problems with your account/profile or the Site, administer a contest, sweepstakes or promotions, or to provide information to third parties at your request such as potential employers, job matching services or third party websites, or other career related services); (iii) personalize your experience by providing content on the Site, including targeted advertising or job-related advertising, that we believe can be of most interest to you; (iv) improve the Site, and; (v) provide you with a safe, efficient, and customized experience.
                <br />
                <b>(b) Analyze and Aggregate Non-Personal Information.</b> We use aggregate information about our users and non-personal information to analyze Site and user behavior and prepare aggregated reports.
                <br />
                <b>(c) Prevent Fraud and Comply with the Law.</b> We use information from the Site to prevent, detect, and investigate fraud, security breaches, and potentially prohibited or illegal activities, establish, exercise or defend a legal claim, and enforce our Terms of Use. We also use information in response to requests by government or law enforcement authorities, or in response to complaints where we are under a legal or regulatory obligation to adhere to a complaint handling procedure.
                <br />
                <b>(d) Marketing & Communications.</b> We use your information to contact you to maintain the Site, your account, or to comply with your stated communication preferences, or to provide other services as described at the time when we collect the information from you, or to contact you for other purposes authorized by law. If you have chosen to participate in some of our career building Services, the information you share with us can be provided to third parties such as (i) resume editors, (ii) employers or recruiters who can contact you about career opportunities, (iii) employers seeking candidates, and/or (iv) other career service providers.
                <br />
                <b>(e) Mobile Data.</b> When you access the Site on a mobile device, we can use the information collected for any purpose set forth in this Privacy Policy. For example, we can use this information to provide you with location-based services, such as advertising, search results, and other personalized content. To the extent permissible by applicable law, we retain Information for as long as it is necessary to fulfill the purpose(s) for which it was collected, our business purposes, and/or to comply with the applicable laws and regulations.
            `
        },
        {
            title: 'Disclosure',
            body: `
                Information we collect can be shared with:
                <br />
                <b>(a) The Provider Corporate Family.</b> We may disclose the information we collect about you with the Provider’s corporate family (our affiliates and subsidiaries). If we disclose your information with them, their use and disclosure of your information will be subject to this Policy.
                <br />
                <b>(b) Service Providers.</b> We may disclose your information to service providers under contract who help with our business operations (such as, but not limited to, fraud investigations, bill collection, payment processing, and site analytics and operations.
                <br />
                <b>(c) Additional Services Requests.</b> On the Site, you have opportunities to express interest in or register for other services. If you do, we will provide information about you to those third parties, or parties working on their behalf, to implement your request. We may share your Information with third parties to whom you ask us to send your information (such as resume editors, employers, recruiters or job posting third-party websites or others about whom you are otherwise notified and consent to when using a specific service).
                <br />
                <b>(d) Legal and Law Enforcement.</b> We may disclose information that we collect if we believe that such action is necessary to comply with the law, a judicial proceeding, court order, or other legal process, such as in response to a court order or a subpoena.
                <br />
                <b>(e) Change of Control.</b> We may share your information if there is a bankruptcy proceeding, if substantially all of our assets are transferred to another company, or if we merge with or acquired by another business entity. If any of these events take place, we will make reasonable efforts to request that the new service provider follows this Privacy Policy with respect to your information.
                <br />
                <b>(f) Ad Networks.</b> On the Site, we work with third parties to serve advertisement, provide us with data collection, reporting, ad response measurement and site analytics and assist with delivery of relevant marketing messages and advertisements. These third parties can view, edit or set their own tracking technologies/cookies.
                <br />
                <b>(g) Analytics Providers.</b> We may disclose information we collected through our Site to our analytics providers such as Google Analytics, to evaluate usage of our Site, and to help us improve our services, performance and user experiences.
                <br />
                <b>(h) To Protect Us and Others.</b> We may share your information where we believe it is necessary to investigate, prevent, or act regarding illegal activities, suspected fraud, potential threats to the safety of any person, violations of our Terms of Use, or this Policy, or as evidence in litigation in which we are involved.
                <br />
                <b>(i) Aggregate and De-Identified Information.</b> We may share information that has been aggregated and de-identified with third parties for marketing, advertising, research, or similar purposes.
                <br />
                <b>(j) Your Consent.</b> By agreeing to this Privacy Policy, we have obtained your consent, when required, for the disclosure of the information we collect.
            `
        },
        {
            title: 'Accessing, Reviewing, and Changing Your Personal Information',
            body: `
                Registered users  may review and change the Information at any time by accessing their accounts on the Site. You must promptly update your information if it changes or becomes inaccurate.     
            `
        },
        {
            title: 'Information You Share on the Site',
            body: `
                <b>(a) Public Information.</b> If you participate in Provider services (e.g., resume posting, profile creation, Job Alerts) that permit you or Provider on your behalf to post your Information on third-party sites (e.g., websites, bulletin boards, personal URLs) by posting information or content (such as by posting photos, or participating in online forums or communities), or when you interact with our Site through social media sites, plug-ins or other sites or applications, depending upon your privacy settings, the information posted can become public on the Internet. We cannot prevent further use of this information. Please refer to those third-party sites’ privacy policies and terms of use to learn more about their privacy practices.
                <br />
                <b>(b) Other services.</b> If you use the Site, Provider can create a universal application derived from the information you provide on the Site that will be visible to potential employers who have immediate needs for prospects as well as visitors to the Site. Therefore, your Site Profile can be publicly viewable. You can elect to have your Profile restricted to registered users only by following the instructions on the Site for that particular setting. You agree that employers and Provider will use the contact information you have provided to contact you by email and you understand that this consent is not a condition to having access to the Site or Provider services.
                <br />
                <b>(c) Scores, ratings and other tools.</b> As part of the Site offering you may interact with tools that provide matching, check and recommendation mechanisms regarding the information you have provided in the system when creating your resume. These tools are computer based algorithms that have been created to help you in your career finding journey and do not ensure that you will get a job offering. When you make use of these tools you also agree and understand that Provider cannot ensure that you will get contacted by an Employer or receive a job offering, even when your resume has received a high “strength” number. Your use of these tools is completely at your own risk and provides no warranties whatsoever.    
            `
        },
        {
            title: 'Third Parties',
            body: `
                Our Site can contain links to other third-party websites and Provider can provide products and services that share information with third-party sites for your job search. We are not responsible for the privacy practices or the content of these third-party sites so it is important to familiarize yourself with their privacy policies before providing your Information. 
            `
        },
        {
            title: 'Information Security',
            body: `
                We maintain appropriate technical, physical, and administrative security measures designed to protect the security of your information against loss, misuse, unauthorized or accidental access, disclosure, copying, use or alteration. However, no method of transmission over the Internet or method of electronic storage is totally secure. Therefore, we cannot guarantee its absolute security.    
            `
        },
        {
            title: `Children's Privacy`,
            body: `
                The Site is a general audience site and it is not intended for children under the age of 16. We do not knowingly collect information via our websites, applications, services, or tools from users in this age group. Our websites are equipped to block the registration of a minor under 16 years old. If you become aware that a child under 16 has provided us with information, please contact us immediately so we can delete such information from our system.    
            `
        },
        {
            title: 'Storage and International Transfer',
            body: `
                As we operate internationally, and many of our computer systems are currently based globally, for the purposes set out in this Privacy Policy your Information can be stored and processed elsewhere by us, our affiliates or an unaffiliated service provider. The data protection and privacy regulations do not always offer the same level of protection as in other parts of the world. Nonetheless, we have ensured that our service providers and affiliates have been subjected to strict contractual provisions in their contract with us to guarantee that an adequate level of data protection for your data is met. When you create an account/profile or otherwise provide us with your information, you consent to the collection, storage, use of some tracking technologies as described above, and/or processing of your information, including personal information, in all countries. To obtain a copy of the relevant transfer mechanism or additional information on the transfers, please address these requests by any of the means provided in our contact page. 
            `
        },
        {
            title: 'Notification Regarding Review and Updates.',
            body: `
                From time to time and at least once every twelve (12) months we review this Privacy Policy and we may choose to update its terms. The Privacy Policy posted at any time or from time to time via the Site shall be deemed to be the Privacy Policy then in effect. You agree that we can notify you about material changes in the way we treat your Information by placing a notice on the Site. You should check the Site frequently for updates. 
            `
        }
    ]

    return (
        <PageContainer title='Privacy Policy'>
            <div className="banner categories text-center" style={{ minHeight: isTab ? '70vh' : '75vh' }}>
                <Container className="container-padding">
                    <Grid container style={{ position: 'relative', zIndex: 1 }} justifyContent='center'>
                        <Grid container direction="column" item xs={10} alignItems="center" style={{ margin: isTab ? '30% 0 10%' : 0 }}>
                            <h1>Privacy Policy</h1>
                            <p>
                                Thank you for visiting RetroResume (“Provider”, “we”, “us”). To help inform our customers (“Customers,” “you” or “your”), we provide this notice ("Policy") explaining our online information practices and the choices you can make about the collection and use of the data and information you submit (“Information” or “Data”) or we collect through our family of websites that link to this Privacy Policy as well as our other related mobile websites, mobile Provider applications ("mobile apps" or “Provider Applications”), services, tools, and other applications that link to this Privacy Policy (all of these collectively, the "Site").
                            </p>
                            <p>
                                By visiting the Site, you agree that your information will be handled as described in this Policy. Your use of this Site is subject to this Policy and our Terms of Use. Provider’s Terms of Use are incorporated by reference into this Policy.
                            </p>
                        </Grid>
                    </Grid>

                </Container>
            </div>
            <Container className='container-padding pt-2 pb-5'>
                <AccordionComponent items={policies} />
            </Container>
        </PageContainer>
    )
}

export default PrivacyPolicy