import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Grid, IconButton, TextField, Table, TableHead, TableRow, TableCell, TableBody, Chip
} from '@material-ui/core'
import { ZoomInOutlined, InsertDriveFile, InsertPhoto, AttachFile, Refresh } from '@material-ui/icons'
import React, { useEffect, useState, useRef } from 'react'
import AdminContainer from '../../components/AdminContainer'
import { useMediaQuery } from 'react-responsive';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import * as templateApi from '../../redux/api/template'
import authApi from '../../redux/api/auth'
import { emailJS, emailJSUser } from '../../redux/api/config'
import pdf2base64 from 'pdf-to-base64'
import imageToBase64 from 'image-to-base64/browser'

const storage = getStorage();

const Orders = ({ services, user }) => {
    const sm = useMediaQuery({ query: '(min-width: 500px)' })
    const [ openDialog, setOpenDialog ] = useState(false)
    const [ orderView, setOrderView ] = useState(null)
    const [ fetchingOrders, setFetchingOrders ] = useState(false)
    const [ orders, setOrders ] = useState(services?.orders || [])
    const [ templates, setTemplates ] = useState(services?.templates || [])
    const pngRef = useRef(null)
    const pdfRef = useRef(null)

    const [ uploading, setUploading ] = useState(false)
    const [ pdfPercent, setPdfPercent ] = useState(0)
    const [ imagePercent, setImagePercent ] = useState(0)


    const viewOrder = (order) => {
        // const templateView = templates.find(({ slug }))
        setOrderView({ ...order })
        setOpenDialog(true)
    }

    const init = async () => {
        setFetchingOrders(true);
        try {
            await templateApi.getOrders()
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setFetchingOrders(false)
        }
    } 

    const handleDocument = ({ files, name }) => {
        if(files[0]) {
            setOrderView({ ...orderView, [name]: files[0] })
        }
    }

    const handleSubmit = async (order) => {
        if(!order?.pdfFile || !order?.imageFile || !order?.status || uploading) {

        } else {
            try {
                setUploading(true)
                if(!order?.pdfFile?.file && !order?.imageFile?.file) {
                    const pdfFile = await uploadFile({ file: order.pdfFile, orderId: order.orderId, fileType: 'pdf', fileName: `${order.title}.pdf` });
                    const imageFile = await uploadFile({ file: order.imageFile, orderId: order.orderId, fileType: 'jpg', fileName: `${order.title}.jpg` });
                    await templateApi.updateOrder({ ...order, pdfFile, imageFile }, order.orderId);

                    if(typeof(order.pdfFile) !== 'string' || typeof(order.imageFile) !== 'string') {
                        const template = templateApi.getTemplate(order.templateId)
                        const user = authApi.getUserData(order.uid);
                        const pdf_link = await download(pdfFile.file, 'pdf') 
                        const png_link = await download(imageFile.file, 'png');

                        await emailJS.send('retro_noreply', 'resume_completed', {
                            to_name: user?.displayName || '',
                            to_email: user?.email || '',
                            resume_title: template?.title || '',
                            ref_no: 'RRT-' + order?.paymentInfo?.transaction_id || '',
                            pdf_link,
                            png_link
                        }, emailJSUser)
                    }
                } else {
                    await templateApi.updateOrder({ ...order }, order.orderId);
                }
                init()
                setOpenDialog(false)
                setOrderView(null)
                setImagePercent(0); setPdfPercent(0)
            }
            catch (err) {
                console.log(err)
            }
            finally{
                setUploading(false)
            }
        }
    }

    const download = async (url, type) => {
        return new Promise(async (resolve, reject) => {
            if(url) {
                try {
                    let base64 = '';
                    if(type === 'pdf') {
                        base64 = 'data:image/png;base64,' + await pdf2base64(url);
                    } else if (type === 'png') {
                        base64 = 'data:image/png;base64,' + await imageToBase64(url)
                    }
                    resolve(base64);
                }
                catch (err) {
                    reject('An error occurred.')
                }
            } else {
                resolve('')
            }
        })
    }

    const uploadFile = async ({ file, orderId, fileType, fileName }) => {
        return new Promise((resolve, reject) => {
            const storageRef = ref(storage, `orders/${orderId}/${fileName}`);
            const uploadTask = uploadBytesResumable(storageRef, file);
            // // Pause the upload
            // uploadTask.pause();
    
            // // Resume the upload
            // uploadTask.resume();
    
            // // Cancel the upload
            // uploadTask.cancel();
            uploadTask.on('state_changed', 
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done');
                    
                    if(fileType === 'pdf') setPdfPercent(progress)
                    else setImagePercent(progress);

                    switch (snapshot.state) {
                        case 'paused':
                            console.log('Upload is paused');
                            break;
                        case 'running':
                            console.log('Upload is running');
                            break;
                    }
                }, 
                (error) => {
                    // Handle unsuccessful uploads
                    reject(error)
                }, 
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        const uploaded_at = Date.now();
                        resolve({
                            file: downloadURL,
                            size: file?.size || 0,
                            uploaded_at, uploaded_by: user?.userData?.uid || ''
                        })
                    });
                }
            );
        })
    }

    useEffect(() => {
        setTemplates(services?.templates)
        setOrders(services?.orders)
    }, [services])

    return (
        <AdminContainer title={`All Orders (${orders.length})`} processing={fetchingOrders}>
            <Grid container spacing={3}>
                <Grid item xs={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <Button fullWidth={!Boolean(sm)} style={{ marginTop: sm ? '-3.5rem' : 0, height: '40px' }} 
                        variant="contained" color="primary" onClick={() => init()}
                        startIcon={<Refresh />}
                    >
                        Refresh
                    </Button>
                </Grid>
                <Table>
                    <TableHead>
                        <TableRow style={{ fontWeight: 'bold' }}>
                            <TableCell>S/N</TableCell>
                            <TableCell>User Email</TableCell>
                            <TableCell>Resume</TableCell>
                            <TableCell>Order Amount</TableCell>
                            <TableCell>Order Date</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            orders?.map((order, key) => (
                                <TableRow key={key}>
                                    <TableCell>{key + 1}</TableCell>
                                    <TableCell>{order?.uid}</TableCell>
                                    <TableCell>{order?.title}</TableCell>
                                    <TableCell>{order?.paymentInfo?.currency} {order?.paymentInfo?.amount}</TableCell>
                                    <TableCell>{moment(order?.created).format('DD/MM/YYYY')}</TableCell>
                                    <TableCell>
                                        <Chip className={`${order?.status} chip`} label={order?.status} />
                                    </TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => viewOrder(order)}><ZoomInOutlined /></IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </Grid>

            <Dialog open={openDialog && orderView} maxWidth="md" disableEscapeKeyDown disableBackdropClick>
                <DialogContent className='doc-dialog pr-2 pl-2 pt-2 pb-2'>
                    { orderView && <Grid container spacing={3} className='attachment'>
                        <Grid item xs={12} md={6} container alignItems='center'>
                            <div className='doc-container' onClick={() => pdfRef.current.click()}>
                                { orderView?.pdfFile ? <InsertDriveFile /> : <AttachFile /> }
                            </div>
                            <div className='pl-05'>
                                <p><span>File Type:</span> PDF</p>
                                <p><span>Uploaded At:</span> {orderView?.pdfFile?.uploaded_at && moment(orderView?.pdfFile?.uploaded_at).format('DD/MM/YYYY')}</p>
                                <p><span>Uploaded By:</span> </p>
                                <p><span>Size:</span> {parseFloat(orderView?.pdfFile?.size/1000 || 0).toFixed(2)} KB</p>
                                { (pdfPercent > 0) && <p><span>Uploading:</span> {pdfPercent}%</p> }
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} container alignItems='center'>
                            <div className='doc-container' onClick={() => pngRef.current.click()}>
                                { orderView?.imageFile ? <InsertPhoto /> : <AttachFile /> }
                            </div>
                            <div className='pl-05'>
                                <p><span>File Type:</span> PNG/JPG</p>
                                <p><span>Uploaded At:</span> {orderView?.imageFile?.uploaded_at && moment(orderView?.imageFile?.uploaded_at).format('DD/MM/YYYY')}</p>
                                <p><span>Uploaded By:</span> </p>
                                <p><span>Size:</span> {parseFloat(orderView?.imageFile?.size/1000 || 0).toFixed(2)} KB</p>
                                { (imagePercent > 0) && <p><span>Uploading:</span> {imagePercent}%</p> }
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField fullWidth name='status' value={orderView?.status} select SelectProps={{ native: true }} 
                                variant='outlined' label='Order Status' InputLabelProps={{ shrink: true }}
                                onChange={(e) => setOrderView({ ...orderView, status: e.target.value }) } 
                            >
                                <option value=''>Select an option</option>
                                <option value='pending'>Pending</option>
                                <option value='active'>Active</option>
                                <option value='completed'>Completed</option>
                                <option value='cancelled'>Cancelled</option>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}><span>Resume Title:</span> {orderView?.title}</Grid>
                        <Grid item xs={12} sm={6} md={4}><span>Amount:</span> {orderView?.paymentInfo?.currency} {orderView?.paymentInfo?.amount}</Grid>
                        <Grid item xs={12} sm={6} md={4}><span>Order Date:</span> {moment(orderView?.created).format('DD/MM/YYYY')}</Grid>
                        <Grid item xs={12} sm={6} md={4}><span>Title:</span> {orderView?.title}</Grid>

                        <input hidden type='file' accept='image/*' ref={pngRef} name='imageFile' onChange={(e) => handleDocument(e.target)} />
                        <input hidden type='file' accept='application/pdf' ref={pdfRef} name='pdfFile' onChange={(e) => handleDocument(e.target)} />
                    </Grid>}
                </DialogContent>
                <DialogActions className='pr-2 pl-2 pt-2 pb-2'>
                    <Button disabled={uploading} variant='contained' color='secondary' onClick={() => { setOpenDialog(false); setOrderView(null);  }}>Cancel</Button>
                    <Button variant='contained' color='primary' onClick={() => handleSubmit(orderView)}>
                        { uploading ? <CircularProgress color='inherit' size={15} /> : 'Update' }
                    </Button>
                </DialogActions>
            </Dialog>
        </AdminContainer>
    )
}

const mapStateToProps = ({ services, user }) => ({ services, user })

export default connect(mapStateToProps)(Orders);
