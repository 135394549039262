import { SET_TEMPLATES, ADD_TO_WISHLIST, ADD_TO_CART, SET_CURRENCY, SET_UTILITIES, SET_ANALYTICS, SET_ORDERS } from "./constants";
import * as template from "../api/template";
import { store } from "../../App";

export const fetchTemplates = () => {
    return async (dispatch) => {
        try {
            const allTemplates = await template.getTemplates();
            await dispatch({
                type: SET_TEMPLATES,
                payload: allTemplates,
            });
            return Promise.resolve(allTemplates);
        } catch (error) {
            return Promise.reject(error);
        }
    };
};

export const fetchAnalytics = () => {
    return async (dispatch) => {
        try {
            const analytics = await template.analytics();
            await dispatch({
                type: SET_ANALYTICS,
                payload: analytics,
            });
            return Promise.resolve(analytics);
        } catch (error) {
            return Promise.reject(error);
        }
    };
};

export const fetchUtilities = () => {
    return async (dispatch) => {
        try {
            const utilities = await template.getUtilities();
            await dispatch({
                type: SET_UTILITIES,
                payload: utilities,
            });
            return Promise.resolve(utilities);
        } catch (error) {
            return Promise.reject(error);
        }
    };
};

export const handleCart = (template, type = 'add') => {
    return async (dispatch) => {
        try {
            let cartList = store.getState().services?.cartList;
            if(type === 'add') cartList.push(template)
            else if (type === 'clear') cartList = []
            else cartList = cartList.filter(temp => temp.id !== template.id)
            await dispatch({
                type: ADD_TO_CART,
                payload: cartList,
            });
            return Promise.resolve(cartList);
        } catch (error) {
            return Promise.reject(error);
        }
    };
};

export const handleWishList = (template, type = 'add') => {
    return async (dispatch) => {
        try {
            let wishList = store.getState().services?.wishList;
            if(type === 'add') wishList.push(template)
            else wishList = wishList.filter(temp => temp.id !== template.id)
            await dispatch({
                type: ADD_TO_WISHLIST,
                payload: wishList,
            });
            return Promise.resolve(wishList);
        } catch (error) {
            return Promise.reject(error);
        }
    };
};

export const setCurrencyCode = (code) => {
    return async (dispatch) => {
        try {
            await dispatch({
                type: SET_CURRENCY,
                payload: code,
            });
            return Promise.resolve(code);
        } catch (error) {
            return Promise.reject(error);
        }
    };
};

export const fetchOrders = () => {
    return async (dispatch) => {
        try {
            const orders = await template.getOrders();
            await dispatch({
                type: SET_ORDERS,
                payload: orders,
            });
            return Promise.resolve(orders);
        } catch (error) {
            return Promise.reject(error);
        }
    };
};
