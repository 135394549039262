import { Button, Container, Dialog, DialogActions, DialogContent, Divider, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PageContainer from '../components/PageContainer';
import { fetchTemplates } from '../redux/actions/serviceActions'
import { Link, useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Adjust, CheckCircle, ErrorOutline, Payment, WhatsApp } from '@material-ui/icons';
import { getPrice } from '../util';
import { FlutterWaveButton, closePaymentModal } from 'flutterwave-react-v3';
import * as templateApi from '../redux/api/template';
import { emailJS, emailJSUser } from '../redux/api/config'
import { CircularProgress } from '@mui/material';

const phone = '09059952547'

const SingleTemplate = ({ fetchTemplates, user, services }) => {
    const isTab = useMediaQuery({ query: '(max-width: 1000px)' })
    const history = useHistory()
    const [ template, setTemplate ] = useState(null)
    const [ utilities, setUtilities ] = useState(null)
    const [ currencyCode, setCurrencyCode ] = useState('NGN')
    const [ fetching, setFetching ] = useState(true)
    const [ paying, setPaying ] = useState(false)
    const [ openDialog, setOpenDialog ] = useState(false)
    const [ userData, setUserData ] = useState(user?.userData)
    const [ text, setText] = useState('')
    const config = {
        public_key: 'FLWPUBK-20e7fc9aaef43ddc4bcc856b7d66b53c-X',
        tx_ref: Date.now(),
        amount: 3000,
        payment_options: 'card,mobilemoney,ussd',
    };

    const fetchCategories = async () => {
        setFetching(true)
        try {
            const slug = history.location.pathname.split('template/')[1];
            const res = await fetchTemplates();
            setTemplate(res.find(rs => rs.slug === slug ) || null)
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        fetchCategories();
    }, [])

    useEffect(() => {
        setUserData(user?.userData || null);
    }, [user])

    useEffect(() => {
        setUtilities(services.utilities)
        setCurrencyCode(services.currencyCode)
    }, [services])

    useEffect(() => {
        if(template) {
            setFetching(false)
            setText(`Hello, i would love to purchase the ${template.title} Resume Template with Id Number: RRT-${String(template.id).padStart(4, '0')}`)
        } else {
            setText('')
        }
    }, [template])

    const fwConfig = {
        ...config,
        text: <>{ paying ? <CircularProgress size={15} color='inherit' /> : <><Payment className='mr-05' />Continue Online</> }</>,
        callback: async (response) => {
            const {
                amount = 0,
                currency = '',
                flw_ref = '',
                transaction_id = '',
                tx_ref = ''
            } = response
            try {
                setPaying(true)
                await continueOnline({ amount, currency, flw_ref, transaction_id, tx_ref })
            } catch (err) {
                console.log(err)
            } finally {
                closePaymentModal() // this will close the modal programmatically
                setPaying(false);
            }
        },
        onClose: () => {},
    };

    const continueOnline = async (response = null) => {
        if(user.authenticated) {
            const ap = template.requiredFields.map((field) => typeof(userData[field]) === 'string' ? userData[field] ? 0 : 1 : userData[field]?.length > 0 ? 0 : 1)?.reduce((total, num) => total + num)
            await templateApi.saveOrder({ 
                templatePrice: template.price,
                templateSales: template.sales,
                templateId: template.id,
                uid: userData.uid,
                paymentInfo: response,
                status: 'active' 
            });
            await emailJS.send('retro_noreply', 'resume_confirmation', {
                to_name: userData.displayName,
                to_email: userData.email,
                resume_title: template?.title || '',
                ref_no: 'RRT-' + response?.transaction_id || ''
            }, emailJSUser)
            if(ap > 0) {
                setOpenDialog(true)
            } else {
                history.push('/account/orders')
            }
        } else {
            history.push('/sign-in', { redirect: `/template/${template?.slug}` })
        }
    }

    const test = async () => {
        const ref = {
            amount: 10,
            currency: "NGN",
            flw_ref: "NoukaanInnovati/FWVO4932929814755",
            transaction_id: 585956320,
            tx_ref: 1645896554948
        }
        await continueOnline(ref)

    }

    return (
        <PageContainer processing={fetching} secondaryLoader>
            <Container className="container-padding mb-5" >
                { Boolean(template) && <Grid style={{ marginTop: isTab ? '15%' : '10%' }} alignItems='center' justifyContent='center' container spacing={3}>
                    <Grid item xs={12} md={6} container justifyContent='center'
                        style={{ backgroundColor: '#F8FBFF', borderRadius: '10px' }}
                    >
                        <img alt="template" src={template.image} 
                            style={{ width: '100%', maxWidth: '400px', objectFit: 'contain', margin: 'auto' }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} container direction="column" >
                        <h2 className='mb-0 text-center'>{template.title}</h2>
                        <h3 className='mt-0'>{getPrice(template.price, template?.sales)}</h3>
                        {/* <p>{}</p> */}
                        <small className='text-center mb-05'>Make payment online and get your resume sent to your email within 24hrs</small>
                        {   user.authenticated ?
                            <FlutterWaveButton {...fwConfig} 
                                amount={getPrice(template.price, template?.sales, true)} 
                                className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary cursor-pointer"
                                currency={currencyCode}
                                customer={{
                                    email: userData?.email,
                                    phonenumber: userData?.phoneNumber || '',
                                    name: userData?.firstName || '' + ' ' + userData?.lastName || '',
                                }}
                                customizations={{
                                    title: `Payment for ${template?.title} Resume Template`,
                                    description: `Payment for ${template?.title} Resume Template with Id Number: RRT-${String(template?.id).padStart(4, '0')}`,
                                    logo: 'https://www.retroresume.com/retroresume-sqr.png',
                                }}
                            />
                            :
                            <Button onClick={continueOnline} variant='contained' color="primary" className='cursor-pointer' startIcon={<Payment />}>Continue Online</Button>
                        }
                        <br />
                        <Divider />
                        <h5 className='mb-0'>Required Information</h5>
                        <Grid container spacing={1}>
                            {
                                template?.requiredFields?.map((field, key) => (
                                    <Grid item key={key}>
                                        <span style={{ fontSize: '14px' }}>
                                            {
                                                utilities?.fields?.find(({ id }) => id == field)?.name
                                            }
                                        </span>
                                    </Grid>
                                ))
                            }
                        </Grid>
                        <br />
                        <Divider />
                        <a
                            href={`https://wa.me/${phone}?text=${text}`} className="mt-1"
                            target="_blank" style={{ width: '100%', display: 'block' }}
                        >
                            <Button variant='contained' style={{ background: '#25D366', color: '#fff', cursor: 'pointer' }} fullWidth startIcon={<WhatsApp />}>Chat on WhatsApp</Button>
                        </a>
                        <small className='text-center mt-05'>Need Support? Chat with our support staff to complete order</small>
                    </Grid>
                </Grid>}
                {/* <Button onClick={test}>test</Button> */}
                <Dialog open={openDialog}>
                    <DialogContent>
                        <Typography style={{ fontWeight: 'bold', fontSize: 18 }}>Order Payment Confirmed</Typography>
                        <Typography>
                            Kindly note that your order status is still <span style={{ color: 'orangered' }}>Pending</span> as some information required 
                            to generate the Resume is missing from your profile
                        </Typography>
                        <Typography className="mt-1 mb-05" style={{ fontSize: 14, fontStyle: 'italic' }}>
                            See missing information below:
                        </Typography>
                        <Grid container spacing={1}>
                            {
                                Boolean(userData) && template?.requiredFields?.map((field) => 
                                    typeof(userData[field]) === 'string' ? userData[field] ? <></> : 
                                    <Grid container alignItems='center' item xs={12} sm={6}>
                                        <ErrorOutline color='error' style={{ fontSize: 14, marginRight: '0.3rem' }} />
                                        {utilities?.fields?.find(({ id }) => id === field )?.name}
                                    </Grid>
                                    : 
                                    userData[field]?.length > 0 ? <></> : 
                                    <Grid item xs={12} sm={6} container alignItems='center'>
                                        <ErrorOutline color='error' style={{ fontSize: 14, marginRight: '0.3rem' }} />
                                        {utilities?.fields?.find(({ id }) => id === field )?.name}
                                    </Grid>
                                )

                            }
                        </Grid>
                    </DialogContent>
                    <DialogActions className='pr-1 pb-1'>
                        <Button variant="contained" color="primary"
                            onClick={() => {
                                setOpenDialog(false);
                                history.push('/account/profile');
                            }}
                        >Update Information</Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </PageContainer>
    )
}

const mapStateToProps = ({ user, services }) => ({ user, services });

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ fetchTemplates }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleTemplate)