import AccountContainer from '../../components/Account/AccountContainer'
import { baseUrl } from '../../redux/api/config';
import { getPdf, getPrice, toast } from '../../util';
import { IconButton, Button, Divider, Grid, Table, TableHead, TableRow, TableCell, TableBody, Chip, Popover } from '@material-ui/core'
import { DeleteForever } from '@material-ui/icons'
import { FileDownloadOutlined, PreviewOutlined, VisibilityOutlined } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link, useHistory } from 'react-router-dom';
import * as templateApi from '../../redux/api/template';
import moment from 'moment'
import { ListItemText, List } from '@mui/material';
import { saveAs } from 'file-saver'
import pdf2base64 from 'pdf-to-base64'
import imageToBase64 from 'image-to-base64/browser'

const Orders = ({ user, services }) => {
    const history = useHistory()
    const [userData, setUserData ] = useState(user?.userData)
    const [templates, setTemplates] = useState([])
    const [orders, setOrders] = useState([])
    const [viewOrder, setViewOrder] = useState(null)
    const [ignore, setIgnore] = useState(false)
    const [fetching, setFetching ] = useState(false)

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    // const id = open ? 'simple-popover' : undefined;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const init = async () => {
        try {
            setFetching(true)
            const or = await templateApi.getUserTemplates(userData?.uid);
            setOrders(or);
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setFetching(false)
        }
    }

    const download = async ({ functionName: template, title }, type) => {
        // saveAs(url, title + ".png");
        try {
            let ext = '';
            const link = document.createElement('a');
            const base64 = await getPdf(template, userData)

            if(type === 'pdf') {
                ext = 'data:image/png;base64,'
            } else if (type === 'png') {
                ext = 'data:image/png;base64,'
            }

            link.href = ext + base64;
            link.setAttribute('download', `${title} - ${userData?.firstName || ''} ${userData?.lastName || ''}.${type}`); //or any other extension
            document.body.appendChild(link);
            link.click();

            setAnchorEl(null)
            // openFileInNewBrowser(base64 || '', true, 'application/pdf')
        }
        catch (err) {
            alert('An error occurred.')
        }
    }

    useEffect(() => {
        setUserData(user?.userData)
    }, [user])

    useEffect(() => {
        setTemplates(services?.templates)
    }, [services])

    useEffect(() => {
        if(orders.length > 0 && templates.length > 0 && ignore === false ) {
            getTemplateInfo(orders, templates);
            setIgnore(true)
        }
    }, [templates, orders])

    const getTemplateInfo = (or) => {
        setOrders(or.map((order) => {
            const temp = templates?.find(tem => tem.id === order.templateId) || null
            return {
                ...order,
                title: temp?.title || '',
                image: temp?.image || ''
            }
        }))
    }

    // useEffect(() => {
    //     console.log(orders)
    // }, [orders])

    useEffect(() => {
        if(userData) init();
    }, [])

    return (
        <AccountContainer secondaryLoader processing={fetching} title={`All Orders (${orders?.length || 0} item${orders?.length === 1 ? '' : 's'})`}>
            <Grid container>
                <div style={{ height: 400, width: '100%', overflowX: 'scroll' }}>
                    <Table>
                        <TableHead>
                            <TableRow style={{ fontWeight: 'bold' }}>
                                <TableCell>S/N</TableCell>
                                <TableCell>Resume Image</TableCell>
                                <TableCell>Resume Title</TableCell>
                                <TableCell>Order ID</TableCell>
                                <TableCell>Order Amount</TableCell>
                                <TableCell>Order Date</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                orders?.map((order, key) => (
                                    <TableRow key={key}>
                                        <TableCell>{key + 1}</TableCell>
                                        <TableCell>
                                            <img src={order?.image} style={{ width: '100px' }} />                                
                                        </TableCell>
                                        <TableCell>{order?.title}</TableCell>
                                        <TableCell>RRT-{order?.paymentInfo?.transaction_id}</TableCell>
                                        <TableCell>{order?.paymentInfo?.currency} {order?.paymentInfo?.amount}</TableCell>
                                        <TableCell>{moment(order?.created).format('DD/MM/YYYY')}</TableCell>
                                        <TableCell>
                                            <Chip className={`${order?.status} chip`} label={order?.status} />
                                        </TableCell>
                                        <TableCell className='btns'>
                                            {
                                                order?.status === 'active' &&
                                                <>
                                                    <IconButton title='Download' aria-describedby={order?.orderId} onClick={handleClick}><FileDownloadOutlined /></IconButton>
                                                    {/* <IconButton title='View' aria-describedby={order?.orderId} onClick={handleClick}><VisibilityOutlined /></IconButton> */}
                                                </>
                                            }
                                        </TableCell>

                                        <Popover
                                            id={order?.orderId}
                                            open={open}
                                            anchorEl={anchorEl}
                                            onClose={handleClose}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                        >
                                            <List className='pr-1 pl-1'>
                                                <ListItemText onClick={() => download(order, 'pdf')} style={{ fontWeight: 'bold', cursor: 'pointer' }} primary='Pdf' />
                                                <ListItemText onClick={() => download(order, 'png')} style={{ fontWeight: 'bold', cursor: 'pointer' }} primary='Image' />
                                                
                                            </List>
                                        </Popover>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </div>
            </Grid>
        </AccountContainer>
    )
}

const mapStateToProps = ({ services, user }) => ({ services, user })

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Orders)
