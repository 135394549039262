import { SET_TEMPLATES, ADD_TO_CART, ADD_TO_WISHLIST, SET_CURRENCY, SET_UTILITIES, SET_ANALYTICS, SET_ORDERS } from "../actions/constants";

const defaultState = {
    templates: [],
    cartList: [],
    wishList: [],
    currencyCode: 'NGN',
    utilities: {}
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case SET_TEMPLATES:
            return { ...state, templates: action.payload };
        case ADD_TO_CART:
            return { ...state, cartList: action.payload };
        case ADD_TO_WISHLIST:
            return { ...state, wishList: action.payload };
        case SET_CURRENCY:
            return { ...state, currencyCode: action.payload };
        case SET_UTILITIES:
            return { ...state, utilities: action.payload };
        case SET_ANALYTICS:
            return { ...state, analytics: action.payload };
        case SET_ORDERS:
            return { ...state, orders: action.payload };
        default:
            return state;
    }
};