import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Dashboard from "../pages/admin/Dashboard";
import Resumes from "../pages/admin/Resumes";
import Orders from "../pages/admin/Orders";
import Utilities from "../pages/admin/Utilities";

const AdminRoutes = () => {
    const { path, url } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={`${path}/dashboard`} component={Dashboard} />
            <Route exact path={`${path}/resumes`} component={Resumes} />
            <Route exact path={`${path}/orders`} component={Orders} />
            <Route exact path={`${path}/utilities`} component={Utilities} />
        </Switch>
    )
}

export default AdminRoutes
