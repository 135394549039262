import { initializeApp } from "firebase/app";
import emailJS, { init } from '@emailjs/browser'

const firebaseConfig = {
    apiKey: "AIzaSyCvDxfRWSIQCcxRWoTr8JVrK9LALeo-rmk",
    authDomain: "retro-resume.firebaseapp.com",
    projectId: "retro-resume",
    storageBucket: "retro-resume.appspot.com",
    messagingSenderId: "689663836011",
    appId: "1:689663836011:web:f49afb52a759ed573f162e",
    measurementId: "G-K7QWL7ER5G"
};

const app = initializeApp(firebaseConfig);
const baseUrl = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? 'http://localhost:9000' : 'https://retro-resume.herokuapp.com'

const pkLive = 'pk_live_08d24c6cdb32c2f7d491d7420a84ace03e37c00f'
const pkTest = 'pk_test_6894fe8dcda6bbf884147eed5bca8f381a2bccce'

init('UV8cVsBQBu9UAHiRQ'); // emailJS initialization
const emailJSUser = 'UV8cVsBQBu9UAHiRQ'


export { app, baseUrl, pkLive, pkTest, emailJS, emailJSUser };