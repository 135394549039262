import { USER_LOGIN, USER_LOGOUT, USER_UPDATE } from "./constants";
import auth from "../api/auth";

export const signInUser = (payload, google = false) => {
    return async (dispatch) => {
        try {
            const { uid, signUp } = google ? await auth.googleAuth(payload) : await auth.signIn(payload);
            if(uid) {
                const userData = await auth.getUserData(uid);
                localStorage.setItem("retro-resume", JSON.stringify(userData));
                await dispatch({
                    type: USER_LOGIN,
                    payload: userData,
                });
                return Promise.resolve({ userData, signUp });
            }
        } catch (error) {
            return Promise.reject(error);
        }
    };
};

export const signOutUser = () => {
    return async (dispatch) => {
        try {
            localStorage.removeItem("retro-resume");
            await auth.signOut();
            await dispatch({
                type: USER_LOGOUT
            });
            window.location.href = '/'
            return Promise.resolve(true);
        } catch (error) {
            console.log(error);
            return Promise.reject(error);
        }
    };
};

export const updateUser = (uid) => {
    return async (dispatch) => {
        try {
            const userData = await auth.getUserData(uid);
            localStorage.setItem("retro-resume", JSON.stringify(userData));
            await dispatch({
                type: USER_UPDATE,
                payload: userData,
            });
            return Promise.resolve(userData);
        } catch (error) {
            console.log(error);
            return Promise.reject(error);
        }
    };
};