import { Grid, Popover, IconButton } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setCurrencyCode } from '../redux/actions/serviceActions';
import { ArrowDropDown } from '@material-ui/icons';
import { SECONDARY_COLOR } from '../styles/colors';

const Price = ({ price, services, setCurrencyCode, sales = 0 }) => {
    const [ code, setCode ] = useState(services.currencyCode)
    const [ codeValue, setCodeValue ] = useState(1)
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const setCurrency = (code) => {
        setCurrencyCode(code)
        handleClose();
    };
    
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    useEffect(() => {
        setCode(services.currencyCode);
    }, [services])

    useEffect(() => {
        setCodeValue(code === 'NGN' ? 1 : 411);
    }, [code])

    return (
        <>
            { (sales > 0) && <p className='no-margin text-center' style={{ textDecoration: 'line-through', fontWeight: 'normal', color: SECONDARY_COLOR }}>
                { codeValue === 1 ? '₦' : '$' }
                {Math.ceil(parseFloat(price)/codeValue).toFixed(2)}
            </p>}
            <Grid container alignItems='center' justifyContent='center'>
                <h3 className='no-margin'>
                    { codeValue === 1 ? '₦' : '$' }
                    {Math.ceil(parseFloat((sales > 0) ? sales : price)/codeValue).toFixed(2)}
                </h3>
                <IconButton aria-describedby={id} onClick={handleClick} style={{ padding: 0, minHeight: 'fit-content' }}>
                    <ArrowDropDown />
                </IconButton>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <h6 onClick={() => setCurrency(codeValue !== 1 ? 'NGN' : 'USD')} style={{ margin: '.5rem', cursor: 'pointer' }}>
                        {codeValue !== 1 ? 'NGN' : 'USD'}
                    </h6>
                </Popover>
            </Grid>
        </>
    )
}

const mapStateToProps = ({ services }) => ({ services })

const mapDispatchToProps = ( dispatch ) => {
    return bindActionCreators({ setCurrencyCode }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Price);
