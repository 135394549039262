import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Divider, Grid, IconButton, TextField } from '@material-ui/core'
import { Delete, Add, Refresh } from '@material-ui/icons'
import React, { useEffect, useState, useRef } from 'react'
import AdminContainer from '../../components/AdminContainer'
import { saveUtilities, getUtilities } from '../../redux/api/template'
import { useMediaQuery } from 'react-responsive';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { fetchUtilities } from '../../redux/actions/serviceActions'

const Utilities = ({ services, fetchUtilities }) => {
    const sm = useMediaQuery({ query: '(min-width: 500px)' })
    const [ fetchingUtilities, setFetchingUtilities ] = useState(false)
    const [ utilities, setUtilities ] = useState(services?.utilities)
    const [ updating, setUpdating ] = useState(false)

    const refresh = async () => {
        try {
            setUpdating(true)
            await getUtilities();
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setUpdating(false)
        }
    }

    const addField = () => {
        const field = { name: '', id: '' };
        const fields = utilities?.fields || []
        setUtilities({ ...utilities, fields: [ ...fields, field ]})
    }

    const saveUtilities = async () => {
        try {
            setFetchingUtilities(true)
            await saveUtilities(utilities);
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setFetchingUtilities(false)
        }
    }

    const handleChange = ({ name, value }, index) => {
        let fields = utilities?.fields || []
        fields[index][name] = value;
        setUtilities({ ...utilities, fields: [ ...fields ]})
    }

    useEffect(() => {
        setUtilities(services?.utilities)
    }, [services?.utilities])

    return (
        <AdminContainer title="Utilities" processing={updating}>
            <Grid container spacing={3}>
                <Grid item xs={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <Button fullWidth={!Boolean(sm)} style={{ marginTop: sm ? '-3.5rem' : 0, height: '40px' }} 
                        variant="contained" color="primary" onClick={() => refresh()}
                        startIcon={<Refresh />}
                    >
                        Refresh
                    </Button>
                </Grid>

                <Grid item container spacing={3} alignItems='center'>
                    {
                        utilities?.fields?.map((field, key) => (
                            <>
                                <Grid item xs={12} sm={5}>
                                    <TextField value={field.id} name='id' label='Field Id' variant='outlined' fullWidth onChange={(e) => handleChange(e.target, key)} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField value={field.name} name='name' label='Field Name' variant='outlined' fullWidth onChange={(e) => handleChange(e.target, key)} />
                                </Grid>
                                <Grid item xs={12} sm={1}>
                                    <IconButton style={{ backgroundColor: 'brown', color: '#fff' }}><Delete /></IconButton>
                                </Grid>
                            </>
                        ))
                    }
                    <Grid item container justifyContent='flex-end' xs={12}>
                        <Button color='secondary' variant='contained' onClick={addField} startIcon={<Add />}>Add Field</Button>
                    </Grid>
                </Grid>
                <Grid item container justifyContent='flex-end' xs={12}>
                    <Button color='primary' variant='contained' onClick={saveUtilities}>
                        {  fetchingUtilities ? <CircularProgress size={15} color='inherit' /> : 'Save Utilities' }
                    </Button>
                </Grid>
            </Grid>
        </AdminContainer>
    )
}

const mapStateToProps = ({ services }) => ({ services })

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ fetchUtilities }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(Utilities);
