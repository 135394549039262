import { Button, Grid, TextField } from '@material-ui/core';
import React from 'react'
import { Link } from 'react-router-dom'
import { Container } from '@material-ui/core';
import { Facebook, Instagram, LinkedIn, Twitter, WhatsApp } from '@material-ui/icons';
import AppStore from '../assets/images/Appstore.svg'
import PlayStore from '../assets/images/playstore.svg'

const Footer = () => {
    return (
        <footer className="bg-dark" style={{ padding: '4rem 0 0' }}>
            <Container className="container-padding">
                <Grid container>
                    <Grid item xs={12} md={3} style={{ paddingRight: '1.5rem' }}>
                        <Link to="/"><h2 className="logo text-white mb-1">Retro<span className="text-primary">Resume</span></h2></Link>
                        <p style={{ fontSize: '14px', marginTop: 0 }}>Subscribe to our newsletter and always stay up to date on all our newest information</p>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <h3>Links</h3>
                        <p><Link to="/">Home</Link></p>
                        <p><Link to="/templates">Templates</Link></p>
                        <p><Link to="/about-us">About us</Link></p>
                        <p><Link to="/contact-us">Contact us</Link></p>
                        {/* <p><Link to="/dashboard">Dashboard</Link></p> */}
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <h3>Follow us on</h3>
                        <div className="socials">
                            <a target="_blank" href="https://instagram.com/retro_resume"><Instagram /></a>
                            <a target="_blank" href="https://twitter.com/retro_resume"><Twitter /></a>
                            <a target="_blank" href="https://www.linkedin.com/company/79996601/"><LinkedIn /></a>
                            <a target="_blank" href="https://facebook.com/retroresume"><Facebook /></a>
                        </div>
                        <div className="stores mt-1">
                            <Link target="_blank" to="#">
                                <img src={AppStore} alt="app-store" />
                            </Link>
                            <Link target="_blank" to="#">
                                <img src={PlayStore} alt="play-store" />
                            </Link>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <h3>Subscribe to our newsletter</h3>
                        <p style={{ fontSize: '14px' }}>Subscribe to our newsletter and always stay up to date on all our newest information</p>
                        <form className="mt-1 mb-2">
                            <TextField name="email" label="Enter email address"
                                variant="outlined" fullWidth
                            />
                            <Button variant="contained" color="primary" className="mt-1" fullWidth>
                                Subscribe
                            </Button>
                        </form>
                    </Grid>
                    <Grid item xs={12} className="bottom-footer">
                        <p className="text-center">&copy; { new Date().getFullYear() } RetroResume. All rights reserved | {' '}
                            <Link to='/terms-conditions'>Terms of use</Link> | {' '} <Link to='/privacy-policy'>Privacy Policy</Link>
                        </p>
                    </Grid>
                </Grid>
            </Container>
        </footer>
    )
}

export default Footer
