import { Container, Grid, Avatar, Button, IconButton, Divider, Drawer, TextField, LinearProgress } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useHistory } from 'react-router-dom'
import { ReactComponent as MenuIcon } from '../../assets/images/menu-icon.svg'
import { connect } from 'react-redux'
import { signOutUser } from '../../redux/actions/userActions'
import { bindActionCreators } from 'redux'
import { PRIMARY_COLOR } from '../../styles/colors'
import { useMediaQuery } from 'react-responsive';
import { ExitToApp, Close, FavoriteBorderOutlined, ShoppingCartOutlined, PaymentOutlined, DescriptionOutlined, FormatListBulletedOutlined, PersonOutline } from '@material-ui/icons';
import Loader from '../Loader'

const AccountContainer = ({ title = '', processing, user, signOutUser, tabs = [], currentSection = '', children, secondaryLoader = false }) => {
    const [ userData, setUserData ] = useState(user?.userData || {})
    const isTab = useMediaQuery({ query: '(max-width: 1000px)' })
    const [openMenu, setOpenMenu] = useState(false)
    const [section, setSection] = useState(currentSection)
    const history = useHistory()

    const links = [
        // { name: 'Dashboard', url: '/dashboard', icon: <FormatListBulletedOutlined /> },
        { name: 'Profile', url: '/profile', icon: <PersonOutline /> },
        { name: 'Orders', url: '/orders', icon: <DescriptionOutlined /> },
        // { name: 'Payments', url: '/payments', icon: <PaymentOutlined /> },
        // { name: 'My Cart', url: '/cart', icon: <ShoppingCartOutlined /> },
        // { name: 'Wishlist', url: '/wishlist', icon: <FavoriteBorderOutlined /> }
    ]

    useEffect(() => {
        setUserData(user?.userData || {})
    }, [user?.userData])

    useEffect(() => {
        setSection(currentSection)
    }, [currentSection])

    const signOut = async () => {
        await signOutUser()
    }

    const isActive = (link) => {
        return ( window.location.pathname.includes('/account' + link)) ? 'active' : '' 
    }

    useEffect(() => {
        window.addEventListener('scroll', (e) => {
            const header = document?.querySelector('#header') || null
            const scroll = document.documentElement.scrollTop || document.body.scrollTop;
            if(header) {
                (scroll > 100) ? header?.setAttribute('style', "background-color: #AFD1FF") : header?.removeAttribute('style')
            }
        })
    }, [])

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
            <Helmet><title>{title && `${title} - `}Account | Retro Resume</title></Helmet>

            { processing && <>{secondaryLoader ? <Loader/> : <LinearProgress /> }</>}

            <Container className="container-padding">
                <Grid container justifyContent={ isTab ? "space-between" :"center"} alignItems="center" spacing={3} className="pt-1 pb-1">
                    <Grid item>
                        <Link to="/"><h2 className="logo no-margin">Retro<span className="text-primary">Resume</span></h2></Link>
                    </Grid>

                    { !isTab ?
                        <>
                            <Grid item style={{ flex: 1 }} alignItems="center" container justifyContent='center' className="dashboard-menu">
                                {
                                    links.map(({ url, name, icon }, key) => (
                                        <Link to={'/account'+url} key={key} className={isActive(url)}>
                                            <Button className='pl-1 pr-1' startIcon={icon} >{name}</Button>
                                        </Link>
                                    ))
                                }
                            </Grid> 

                            <Grid item xs={2} container alignItems="center" justifyContent="center">
                                {/* <span>{userData?.displayName || ''}</span> */}
                                <Avatar src={userData?.photoURL || ''} />
                                <IconButton onClick={() => signOut()}><ExitToApp /></IconButton>
                            </Grid>
                        </>
                        :
                        <Grid item>
                            <IconButton onClick={() => setOpenMenu(true)}><MenuIcon /></IconButton>
                        </Grid> 
                    }
                </Grid>
            </Container>

            <div style={{ backgroundColor: PRIMARY_COLOR }}>
                <Container className="container-padding">
                    <Grid container alignItems="center" style={{ padding: '1.5rem 0', color: '#fff' }}>
                        {
                            !isTab ?
                            tabs.map(({ name, url }, key) => (
                                <Link key={key} to={url} style={{ color: (url === section) ? '#fff' : '#86A6D1', margin: '0 .5rem' }}>
                                    {name}
                                </Link>       
                            ))
                            :
                            (tabs.length > 0) &&
                            <TextField className="tab-menu" select SelectProps={{ native: true }} fullWidth variant="outlined" value={section} onChange={(e) => history.push(e.target.value)}>
                                {
                                    tabs.map(({ name, url }, key) => (
                                        <option key={key} value={url}>
                                            {name}
                                        </option>       
                                    ))
                                }
                            </TextField> 
                        }
                    </Grid>
                </Container>
            </div>

            <Container style={{ flex: 1 }} className="container-padding">
                <Grid item xs={12}>
                    <h1>{title}</h1>
                </Grid>

                { children }                
            </Container>
            <footer className="container-padding">
                <Divider className="mt-5" />
                <p style={{ fontSize: '14px', padding: '0 1rem .5rem' }} className="text-center">Copyright ©  { new Date().getFullYear() } RetroResume. All rights reserved. <Link to='/terms-conditions'>Terms of Use</Link> | <Link to='/privacy-policy'>Privacy Policy</Link></p>
            </footer>



            <Drawer open={openMenu} anchor="right" onClose={() => setOpenMenu(false)}>
                <div className="d-flex f-column dashboard-menu" style={{ minWidth: '100vw', minHeight: '100vh', backgroundColor: '#E6EDF5', padding: '1rem 1.5rem' }}>
                    <div className="d-flex" style={{ justifyContent: 'space-between' }}>
                        <Link to="/"><h2 className="logo no-margin">Retro<span className="text-primary">Resume</span></h2></Link>
                        <Button disableRipple disableTouchRipple disableFocusRipple style={{ margin: 0, minWidth: 0 }} onClick={()=> setOpenMenu(false)} ><Close /></Button>
                    </div>
                    <Grid container item direction="column" xs={12} className="mobile-menu">
                        {
                            links.map(({ url, name, icon }, key) => (
                                <Link to={'/account'+url} key={key} className={isActive(url)}>
                                    <Button className='pl-1 pr-1' startIcon={icon} >{name}</Button>
                                </Link>
                            ))
                        }
                        <Link to="#">
                            <Button className='pl-1 pr-1' onClick={() => signOut()} startIcon={<ExitToApp />} >Sign Out</Button>
                        </Link>

                    </Grid>
                </div>
            </Drawer>
        </div>
    )
}

const mapStateToProps = ({ user }) => ({ user })

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ signOutUser }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountContainer)

