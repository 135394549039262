import { toast as toastEmitter } from 'react-toastify';
import Price from './Price'
import { store } from '../App';
import * as definitions from './definitions';
var pdfMake = require("pdfmake/build/pdfmake");
var pdfFonts = require("pdfmake/build/vfs_fonts");
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const customPrice = (price = 0, sales = 0) => {
    let codeValue =  (store?.getState()?.services?.currencyCode === 'NGN') ? 1 : 411;
 
    return Math.ceil(parseFloat((sales > 0) ? sales : price)/codeValue).toFixed(2)
}

const getPrice = (price = '0', sales = 0, valueOnly = false) => {
    if(typeof(price) !== 'string') price = price.toString()

    if(valueOnly) return customPrice(price, sales)
    else return (<Price price={price} sales={sales} />)
}

const toast = (message = '') => {
    toastEmitter.info(message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
    });
}

const getPdf = async (template, details) => {
    return new Promise((resolve, reject) => {
        try {
            pdfMake.createPdf(getDefinition(template, details)).getBase64((base64, err) => {
                if(err) {
                    reject(err)
                } else {
                    resolve(base64)
                }
            })
        }
        catch (err) {
            reject(err)
        }
    })
}

const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
}

export const openFileInNewBrowser = (file, isUrl = false, contentType) => {
    const blobUrl = URL.createObjectURL(isUrl ? b64toBlob(file, contentType) : file);
    window.open(blobUrl, '_blank');
}

export const getDefinition = (template = 'test', details) => {
    return definitions?.[template](details)
}


getDefinition()


export { getPrice, toast, getPdf }