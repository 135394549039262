import React, { useState, useEffect } from 'react'
import AccountContainer from '../../components/Account/AccountContainer'
import * as templateApi from '../../redux/api/template'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Grid } from '@material-ui/core'
import Template from '../../components/Template'

const Resumes = ({ user }) => {
    const [ templates, setTemplates ] = useState([])
    const [ userData, setUserData ] = useState(user?.userData || {})
    const [ processing, setProcessing ] = useState(false)
    
    const init = async () => {
        try {
            setProcessing(true)
            const res = await templateApi.getUserTemplates(userData.uid);
            setTemplates(res);
        }
        catch {}
        finally {
            setProcessing(false)
        }
    }

    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        setUserData(user?.userData || {})
    }, [user])
    
    return (
        <AccountContainer processing={processing} title="My Resumes">
            <Grid container spacing={3} className='mt-2 mb-2'>
                {
                    templates.map(({ template }, key) => (
                        <Grid key={key} item xs={12} sm={6} md={4} lg={3}>
                            <Template template={template} generate={true} />
                        </Grid>
                    ))
                }
            </Grid>
        </AccountContainer>
    )
}

const mapStateToProps = ({ user }) => ({ user })

export default connect(mapStateToProps, null)(Resumes);
