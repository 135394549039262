import { Button, Grid, Container } from '@material-ui/core';
import { Step, Stepper, StepLabel, StepContent } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import PageContainer from '../components/PageContainer';
import BlueBall from '../assets/images/dark-blue.png'
import LightBlueBall from '../assets/images/light-blue.png'
import PinkBall from '../assets/images/pink.png'

import trophy from '../assets/images/trophy.svg'
import tested from '../assets/images/tested.svg'
import easy from '../assets/images/easy.svg'

import dots from '../assets/images/dots.png'
import strips from '../assets/images/strips.png'
import Slider from '../components/Slider';
import AccordionComponent from '../components/Accordion';
import { Link } from 'react-router-dom';

const Home = ({ services }) => {
    const isTab = useMediaQuery({ query: '(max-width: 960px)' })
    const [ templates, setTemplates ] = useState(services?.templates || [])

    const features = [
        {
            icon: '',
            title: 'Approved templates',
            body: ''
        },
        {
            icon: '',
            title: 'Multi-formats resume options',
            body: ''
        },
    ]

    useEffect(() => {
        setTemplates(services?.templates || [])
    }, [services.templates])

    const faqs = [
        {
            title: 'Can I download my resume for free?',
            body: `
            Once you've purchased a resume template and your resume have been generated, you can always download it for free. It will always be available on your account Orders page.
            `
        },
        {
            title: 'How to make a Resume?',
            body: `
                Kindly browse our amazing list of resume templates, select one or more you love and prefer, purchase it, update your account personal information as related to the template you choose and within a short time you will receive an email containing the generated Resume with your information.
                <br />
                Also note that the Resume generated will also be available on your account orders page for you to download anytime.
            `
        },
        {
            title: 'Which is the best Resume Template?',
            body: `
            Every resume template is unique in its own way and serve its purpose. Our suggestion is to go with whichever template catches your attention. Since each recruiters/employers have their preferences, there is no "best resume template" that will be suitable for all the positions/jobs.
            <br />
            So pick any of the template of your choice based on your profession and we guarantee you,  you'll have not just the right or best one but the perfect one.
            `
        },
        {
            title: 'How long should a resume be?',
            body: `
                A resume is not a curriculum vitae (CV), by its very nature, it should be short and simple. It should be a summary of your achievements, leave the rest for the interview.
                <br />
                A resume should never be more than two (2) pages, one (1) page is highly recommend. Also remember, sensitive information like gender and date of birth are totally unnecessary on your resume.
            `
        },
        {
            title: 'Would my resume be made public?',
            body: `
                No. Your resume belongs to you and is only stored privately to your account. You can only access it from your account orders page and also from the email you receive through your registered email address when the order is completed.
                <br />
                You can however download your resume and share to individuals at your choosing. We are also working currently on links you can share to employers/recruiters that they can use to access your generated resume directly. 
            `
        },
        {
            title: 'Are my personal information safe?',
            body: `
                Your personal information are securely stored and only you can add/alter them.
            `
        },
    ]

    const steps = [
        {
            label: 'Select a resume template',
            description: <>
                Click <Link to='/templates'>here</Link> to browse beautiful, creative and professional resume templates that would make the recruiter/employer want to meet you.
                <br />
                Once you find a template, you can proceed to purchase it at the best rate.
            </>,
        },
        {
            label: 'Create an account and update required information',
            description: <>
                <Link to="/sign-up">Sign up</Link> with a valid email address, update all required information for resume generation on your profile, sit back and relax as we generate the resume for you.
            </>,
        },
        {
            label: 'Access Generated Resumes',
            description: <>
                Once templates have been generated, you can find it <Link to="/account/orders">here</Link> (Account/Orders Page).
                <br />
                You can simply download it and share to recruiters/employers. You will also receive an email upon completion of order with the resume attached. You can also download it or save to your drive.
            </>,
        },
    ];

    return (
        <PageContainer title="Home">
            <div className="banner home">
                <Container className="container-padding">
                    <Grid container style={{ position: 'relative', zIndex: 1 }} justifyContent="center" alignItems='center'>
                        <Grid item xs={12} md={8} style={{ margin: isTab ? '30% 0 10%' : 0 }}>
                            <h1>Let's get you a job ready resume</h1>
                            <p>Effortlessly make a job-worthy resume and cover letter that gets you hired faster</p>
                            <Link to="/templates">
                                <Button variant="contained" color="primary">Get Started</Button>
                            </Link>
                        </Grid>
                        <Grid item xs={8} sm={6} md={4}>
                            <Slider templates={templates} />
                        </Grid>
                    </Grid>
                    <div>
                        <img alt="vector" src={PinkBall} className="ball" style={{ left: '5%', top: '35%' }} />
                        <img alt="vector" src={LightBlueBall} className="ball" style={{ left: '44%', top: '15%' }} />
                        <img alt="vector" src={BlueBall} className="ball" style={{ right: '5%', bottom: '10%' }} />
                    </div>
                </Container>
            </div>

            <Container className="container-padding">
                <Grid container className="features why mb-5">
                    <Grid item xs={12} className="title text-center">
                        <p><span>Why choose RETRO</span>RESUME</p>
                        <h2>What makes us different</h2>
                    </Grid>
                    <div>
                        <img alt="vector" src={dots} className="ball" style={{ left: isTab ? '7%' : '7%', top: isTab ? '120%' : '115%', width: isTab ? '100px' : '120px' }} />

                        <img alt="vector" src={dots} className="ball" style={{ right: isTab ? '7%' : '7%', top: isTab ? '200%' : '145%', width: isTab ? '100px' : '120px' }} />
                    </div>
                    <Grid item xs={12} container spacing={3} className="mt-2">
                        <Grid item xs={12} md={4}>
                            <img src={trophy} alt="trophy" />
                            <h3>Interview winning resume</h3>
                            <p className="mt-0">
                                Our resumes are tailored to show why you are the best match for the job by matching your qualification with the job requirements. A dash of diligence and a cup of confidence mix with our well thought out and carefully selected words, you have no fear as the job is already yours.
                            </p>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <img src={easy} alt="trophy" />
                            <h3>Resume writing made easy</h3>
                            <p className="mt-0">
                                With our resume builder, you don’t have to worry about the nitty gritty of resume creation, like font selection, layout, formatting, etc. All you have to do is pick a resume template, fill it in, and then you’re ready to start applying for jobs!
                            </p>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <img src={tested} alt="trophy" />
                            <h3>Tested resume builder</h3>
                            <p className="mt-0">
                                Whatever resume template you're looking for, we've got it! Whether it's a classic black-and-white template, or something a bit more outside the box, our trusted staff have got you covered. We are trusted and have been known to deliver. So sit back and relax and let us give you that dream job.
                            </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container className="mb-5 mt-2">
                    <Grid item xs={12} md={6} container justifyContent='center' alignItems='center' spacing={3}>
                        <img
                            style={{ width: '50%' }}
                            src={templates[Math.floor(Math.random() * templates.length)]?.image} alt="template"
                            alt="template"
                        />
                    </Grid>
                    <Grid item xs={12} md={6} container>
                        <Grid item xs={12} className="title mb-0">
                            <p><span>HOW IT </span>WORKS</p>
                            <h2>Let's help you get the best template ever</h2>
                        </Grid>

                        <Grid item xs={12} className="mt-0">
                            <p>Creating a resume or cover letter has never been this easy! In three simple steps, create the perfect resume to impress hiring employers. Minimum time, maximum professional quality.</p>
                            <Stepper orientation="vertical">
                                {steps.map((step, index) => (
                                    <Step key={index} active = {true}>
                                        <StepLabel>
                                            <b>{step.label}</b>
                                        </StepLabel>
                                        <StepContent>
                                            <p className='no-margin link-primary'>
                                                {step.description}
                                            </p>
                                        </StepContent>
                                    </Step>
                                ))}
                            </Stepper>
                            <Link to="/templates">
                                <Button variant="contained" style={{ minWidth: '250px', minHeight: '45px' }} className='mt-1' color="primary">
                                    Create my template
                                </Button>
                            </Link>

                        </Grid>
                    </Grid>
                    <div>
                        <img alt="vector" src={strips} className="ball" style={{ left: 0, top: isTab ? '200%' : '10%', maxWidth: '100%', zIndex: -1 }} />
                    </div>
                </Grid>

            </Container>
                
            <div className="bg-dark">
                <Container className="container-padding pt-5 pb-5">
                    <Grid container spacing={isTab ? 5 : 3}>
                        <Grid container item xs={12} md={6} alignItems="center" style={{ color: '#fff' }}>
                            <div className="title">
                                <h2 className='mt-0 mb-0'>Create perfect resume for effective interviews</h2>
                            </div>
                            <p style={{ lineHeight: '22px'}}>A great job application leads to a good interview. An amazing resume is what makes it all possible. Start off strong with the hiring manager by creating a positive professional image. A job interview can be much easier if they have a favorable view of your resume and/or cover letter.</p>
                            <Link to='/templates'>
                                <Button className='mt-05' style={{ minWidth: '250px', minHeight: '45px' }} variant="contained" color="primary">Resume Samples</Button>
                            </Link>
                        </Grid>
                        <Grid container direction="column" item xs={12} md={6} alignItems="center" style={{ margin: 'auto' }}>
                            <img 
                                src={templates[Math.floor(Math.random() * templates.length)]?.image} alt="template"
                                style={{ width: '50%', objectFit: 'cover' }}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </div>

            <Container className="container-padding">
                <Grid container justifyContent='center' className="features why mb-5 mt-5">
                    <Grid item xs={12} className="title text-center">
                        <p><span>Frequently asked </span>questions</p>
                        <h2>How can we help you?</h2>
                    </Grid>
                    <Grid item xs={12}>
                        <p className='text-center'>
                            Our 24/7 support agents are always available to provide any support you need on any problem you might encounter and to also help answer any question you might have.
                            In the mean time, here are some possible questions you might have and the answers to those questions.
                        </p>
                    </Grid>
                    <Grid item xs={12} md={11} className='pt-1'>
                        <AccordionComponent items={faqs} />
                    </Grid>

                </Grid>


            </Container>
        </PageContainer>
    )
}

const mapStateToProps = ({ services }) => ({ services });

export default connect(mapStateToProps, null)(Home);
