import React, { useState } from 'react'
import PageContainer from '../components/PageContainer';
import { Button, CircularProgress, Container, Grid, TextField } from '@material-ui/core';
import contact from '../assets/images/contact.svg'
import { useMediaQuery } from 'react-responsive';
import { Facebook, Instagram, Twitter, WhatsApp } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { emailJS, emailJSUser } from '../redux/api/config'
import { toast } from '../util'
import { LinkedIn } from '@mui/icons-material';

const Contact = () => {
    const isTab = useMediaQuery({ query: '(max-width: 1000px)' })
    const [ sending, setSending ] = useState(false)
    const defVal = {
        from_email: '',
        from_name: '',
        subject: '',
        message: ''
    }
    const [ form, setForm ] = useState(defVal)
    
    const handleChange = ({ name, value }) => {
        setForm({ ...form, [name]: value })
    }

    const sendMail = async (e) => {
        e.preventDefault();
        try {
            setSending(true)
            const { from_email, from_name, subject, message  } = form
            if(from_email && from_name && subject && message) {
                await emailJS.send('retro_support', 'contact_form', {
                    from_name,
                    message, subject,
                    from_email,
                }, emailJSUser)
                setForm(defVal)
                toast('Email sent successfully')
            }
        }
        catch(err) {
            console.log(err)
            alert('An error occurred ' + err?.message || '')
        }
        finally {
            setSending(false)
        }
    }
    return (
        <PageContainer title="Contact Us">
            <div className="banner contact">
                <Container className="container-padding">
                    <Grid container style={{ position: 'relative', zIndex: 1 }} alignItems="center">
                        <Grid container direction="column" item xs={12} md={6} style={{ margin: isTab ? '30% 0 10%' : 0 }}>
                            <div className="title">
                                <p>need any <span>help?</span></p>
                                <h1 className="mt-0">Contact Us</h1>
                            </div>
                            <p>
                                Have any remark or question, our customer happiness agent is here to assist you with any issue you are facing. Please give us a call or leave us a message, we will get back to you immediately
                            </p>
                        </Grid>
                        <Grid item xs={12} md={6} container justifyContent="center">
                            <img src={contact} alt="about" className="section-image"/>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div style={{ background: 'linear-gradient(180deg, rgba(230, 237, 245, 0.01) 1.94%, #E6EDF5 59.56%, rgba(230, 237, 245, 0) 99.5%)', padding: isTab ? '4rem 2rem' : '4rem' }}>
                <Container className="container-padding">
                    <form onSubmit={(e) => sendMail(e)}>
                        <Grid container spacing={5} justifyContent="center" className="mt-3 mb-5" style={{ position: 'relative', zIndex: 1 }} alignItems="center">
                            <Grid container justifyContent="center" spacing={3} item xs={12} md={6} className="bg-white">
                                <Grid item xs={12}>
                                    <h2 className="text-center text-primary">Send us a message</h2>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField name='from_name' onChange={(e) => handleChange(e.target)} value={form.from_name} required fullWidth variant="outlined" color="primary" label="Your Name" InputLabelProps={{ shrink: true }} 
                                        placeholder="Enter your name"
                                    />
                                </Grid>

                                
                                <Grid item xs={12}>
                                    <TextField required fullWidth variant="outlined" color="primary" label="Your Email" InputLabelProps={{ shrink: true }} 
                                        placeholder="Enter your email" name='from_email' onChange={(e) => handleChange(e.target)} value={form.from_email}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField required fullWidth variant="outlined" color="primary" label="Subject" InputLabelProps={{ shrink: true }} 
                                        placeholder="Enter subject" name='subject' onChange={(e) => handleChange(e.target)} value={form.subject}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField required fullWidth variant="outlined" color="primary" label="Your Message" InputLabelProps={{ shrink: true }} multiline rows={6} 
                                        placeholder="Enter your message" name='message' onChange={(e) => handleChange(e.target)} value={form.message}
                                    />
                                </Grid>

                                <Grid item xs={9}>
                                    <Button type="submit" variant="contained" color="primary" fullWidth>
                                        { sending ? <CircularProgress color='inherit' size={15} /> : 'SEND MESSAGE' }</Button>
                                </Grid>
                                <Grid container className="mobile-menu mt-1 mb-2">
                                    <div className="mt-0 mb-0 socials">
                                        <a target="_blank" href="https://instagram.com/retro_resume"><Instagram /></a>
                                        <a target="_blank" href="https://twitter.com/retro_resume"><Twitter /></a>
                                        <a target="_blank" href="https://www.linkedin.com/company/79996601/"><LinkedIn /></a>
                                        <a target="_blank" href="https://facebook.com/retroresume"><Facebook /></a>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                    
                </Container>
            </div>
        </PageContainer>
    )
}

export default Contact
