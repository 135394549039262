
import { collection, getDocs, getFirestore, addDoc, where, query, getDoc, doc, updateDoc, increment } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const db = getFirestore();
const storage = getStorage();

const getTemplates = async () => {
    const collectionRef = collection(db, "templates");
    const docsSnap = await getDocs(collectionRef);
    return Boolean(docsSnap.empty) ? [] : docsSnap.docs.map((doc) => { return  { ...doc.data(), id: doc.id }});
}

const getUserTemplates = async (uid) => {
    const collectionRef = collection(db, "orders");
    const q = query(collectionRef, where("uid", "==", uid));
    const docsSnap = await getDocs(q);
    return Boolean(docsSnap.empty) ? [] : docsSnap.docs.map((doc) => { return  { ...doc.data(), orderId: doc.id }});
}

const saveOrder = async (order) => {
    const collectionRef = collection(db, "orders");
    const added = Date.now();
    await addDoc(collectionRef, { ...order, added });
    const docRef = doc(db, "templates", order.templateId);
    return updateDoc(docRef, { orders: increment(1) });
}

// ---------------------------------------------- ADMIN --------------------------------------------------


const getTemplate = async (templateId) => {
    const docRef = doc(db, "templates", templateId);
    const docSnap = await getDoc(docRef);
    return Boolean(docSnap.exists()) ? { templateId, ...docSnap.data() } : {};
}

const getSlug = async (title = '', index = 0) => {
    const slug = title.trim().replaceAll(' ', '-').toLowerCase();
    const collectionRef = collection(db, "templates");
    const querySnapshot = await getDocs(query(collectionRef, where("slug", "==", slug)));
    if(querySnapshot.empty){
        return slug
    } else {
        return this.getSlug(`slug-${index + 1}`, index + 1);
    }
}

const addTemplate = async ({ image, ...payload }) => {
    const created = Date.now();
    const slug = await this.getSlug(payload.title)
    const docRef = await addDoc(collection(db, "templates"), {
        ...payload,
        created, 
        status: 'active', slug
    });
    const imgRef = await this.uploadImage(image, docRef.id)
    const url = await getDownloadURL(imgRef)
    return updateDoc(doc(db, "templates", docRef.id), {
        image: url
    });
    
}

const uploadImage = async (image, id) => {
    const imageRef = ref(storage, `templates/${id}/1.jpg`);
    await uploadBytes(imageRef, image)
    return imageRef
}

const updateTemplate = async (payload, uid) => {
    const updated = Date.now();
    return updateDoc(doc(db, "templates", uid), {
        ...payload,
        updated
    });
}

const deleteTemplate = async (uid) => {
    const deleted_at = Date.now();
    return updateDoc(doc(db, "templates", uid), {
        status: 'deleted', deleted_at
    });
}

const analytics = async () => {
    const templatesRef = collection(db, "templates");
    const usersRef = collection(db, "users");
    const ordersRef = collection(db, "orders");

    const templates = await getDocs(templatesRef);
    const users = await getDocs(usersRef);
    const orders = await getDocs(ordersRef);

    const response = await Promise.all([templates, users, orders])

    return { 
        templates: response[0]?.size || 0,
        users: response[1]?.size || 0,
        orders: response[2]?.size || 0,
        ordersTotal: response[2].size > 0 ? response[2]?.docs?.map((doc) => doc?.data()?.totalAmount || 0)?.reduce((total, num) => total + num) || 0 : 0,
        purchasedTemplates: response[2].size > 0 ? response[2]?.docs?.filter((doc) => doc?.data()?.status === 'completed')?.length || 0 : 0
    }
}

const getOrders = async () => {
    const collectionRef = collection(db, "orders");
    const docsSnap = await getDocs(collectionRef);
    return Boolean(docsSnap.empty) ? [] : docsSnap.docs.map((doc) => { return { ...doc.data(), orderId: doc.id }});
}

const updateOrder = async (payload, uid) => {
    const updated = Date.now();
    return updateDoc(doc(db, "orders", uid), {
        updated,
        ...payload
    });
}

const getUtilities = async () => {
    const docsSnap = await getDoc(doc(db, "utilities", 'general'));
    return Boolean(!docsSnap.exists()) ? {} : docsSnap.data();
}

const saveUtilities = async (utilities) => {
    const updated_at = Date.now();
    return updateDoc(doc(db, "utilities", 'general'), {
        ...utilities,
        updated_at
    });
}

export { 
    analytics, getUtilities, getTemplates, getUserTemplates, getTemplate, getSlug, addTemplate,
    uploadImage, updateTemplate, updateOrder, saveUtilities, saveOrder, getOrders, deleteTemplate
}