import React from 'react'
import { useMediaQuery } from 'react-responsive'
import TinySlider from 'tiny-slider-react'

const Slider = ({ templates = []}) => {
    const isTab = useMediaQuery({ query: '(max-width: 1000px)' })

    const settings = {
        lazyload: true,
        autoplay: true,
        autoplayButtonOutput: false,
        nav: false,
        mouseDrag: true,
        controls: false // remove built-in nav buttons
    }

    return (
        <div>
            <TinySlider settings={settings}>
                {templates.map((el, index) => (
                    <div key={index} style={{ position: "relative" }}>
                        <img
                            className={`tns-lazy-img`}
                            src={el.image}
                            data-src={el.image}
                            alt="template"
                            style={{ objectFit: 'contain', width: '100%', height: isTab ? '90%' : 'auto', margin: '2rem 0' }}
                        />
                    </div>
                ))}
            </TinySlider>
            
        </div>
    )
}

export default Slider
