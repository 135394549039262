import React from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import Cart from "../pages/private/Cart";
import Dashboard from "../pages/private/Dashboard";
import Payments from "../pages/private/Payments";
import Profile from "../pages/private/Profile";
import Resumes from "../pages/private/Resumes";
import Wishlist from "../pages/private/Wishlist";
import Orders from "../pages/private/Orders";

const AccountRoutes = () => {
    const { path, url } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={`${path}/`} component={Profile} >
                <Redirect to={`${path}/profile/personal-information`} />
            </Route>
            <Route exact path={`${path}/profile`} component={Profile} >
                <Redirect to={`${path}/profile/personal-information`} />
            </Route>
            <Route path={`${path}/profile/:section`} component={Profile} />
            <Route exact path={`${path}/orders`} component={Orders} />
            {/* <Route exact path={`${path}/payments`} component={Payments} /> */}
            {/* <Route exact path={`${path}/cart`} component={Cart} /> */}
            {/* <Route exact path={`${path}/wishlist`} component={Wishlist} /> */}
            {/* <Route path="**">
                <Error404 />
            </Route> */}
        </Switch>
    )
}

export default AccountRoutes
