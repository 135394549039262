import { Button, Divider, Grid, InputAdornment, TextField } from '@material-ui/core'
import { FavoriteBorderOutlined, DeleteForever, FavoriteBorder, Favorite } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import AccountContainer from '../../components/Account/AccountContainer'
import { handleCart, handleWishList } from '../../redux/actions/serviceActions'
import { baseUrl } from '../../redux/api/config';
import { getPrice, toast } from '../../util';
import { Link } from 'react-router-dom'
import { PaystackConsumer } from 'react-paystack';
import { pkTest } from '../../redux/api/config'
import * as templateApi from '../../redux/api/template'

const Cart = ({ user, services, handleCart, handleWishList }) => {
    const [cartList, setCartList ] = useState(services?.cartList || [])
    const [ wishList, setWishList ] = useState(services?.wishList || [])
    const [ userData, setUserData ] = useState(user?.userData || {})
    const [ total, setTotal ] = useState(0)
    const [ reference, setReference ] = useState('')

    const removeFromCart = async (template) => {
        await handleCart(template, 'remove')
        toast('Template removed from cart')
    }

    const controlWishList = async (template, type) => {
        if(type === 'add') {
            await handleWishList(template, 'add')
            toast('Template added to Wishlist')
        } else {
            await handleWishList(template, 'remove')
            toast('Template is removed from Wishlist')
        }
    }

    useEffect(() => {
        setCartList(services?.cartList || [])
        setWishList(services?.wishList || [])
    }, [services])

    useEffect(() => {
        setTotal(cartList.map(temp => parseFloat(temp.price)).reduce((a, b) => a + b, 0));
        setReference((new Date()).getTime().toString())
    }, [cartList])

    useEffect(() => {
        setUserData(user?.userData || {})
    }, [user])

    const handleSuccess = async (reference) => {
        try {
            await templateApi.saveOrder({ ...reference, userId: userData.uid, totalAmount: total, templates: cartList })
            Promise.all(
                cartList.map((template) => {
                    // templateApi.savePurchasedTemplates({ template, userId: userData.uid, trxReference: reference.reference })
                })
            ).then(() => {
                handleCart([], 'clear')
            })

        }
        catch (err) {
            console.log(err)
        }
    };
    
    const handleClose = () => {
        console.log('closed')
    }

    const componentProps = {
        publicKey: pkTest,
        onSuccess: (reference) => handleSuccess(reference),
        onClose: handleClose
    };


    return (
        <AccountContainer title={`My Cart (${cartList?.length || 0} item${cartList?.length === 1 ? '' : 's'})`}>
            <Grid container>
                {
                    cartList.map((template, key) => (
                        <Grid container item xs={12} spacing={3} key={key}>
                            <Grid item xs={4} md={2}>
                                <img 
                                    src={baseUrl + `/generate-template-image?template=${template?.functionName}`}
                                    style={{ width: '100%', objectFit: 'cover' }}
                                />
                            </Grid>
                            <Grid item xs={8} md={10} container justifyContent='space-between' direction='row'>
                                <Grid item xs={12}>
                                    <h4 className='template-price text-left'>{getPrice(template?.price)}</h4>
                                    <p className='template-title text-left mt-0'>{template?.title || ''}</p>
                                </Grid>
                                <Grid item xs={12} container alignItems='flex-end'>
                                    {
                                        Boolean(wishList.filter(({ id }) => id === template.id).length === 0) ?
                                        <Button startIcon={<FavoriteBorderOutlined/>} style={{ margin: '.5rem' }}
                                            variant='text' className='text-capitalize' onClick={() => controlWishList(template, 'add')}
                                        >
                                            Add to Wishlist
                                        </Button>
                                        :
                                        <Button startIcon={<Favorite />} style={{ margin: '.5rem' }}
                                            variant='text' className='text-capitalize' onClick={() => controlWishList(template, 'remove')}
                                        >
                                            Remove from Wishlist
                                        </Button>
                                    }

                                    <Button startIcon={<DeleteForever />} style={{ margin: '.5rem' }}
                                        variant='text' className='text-capitalize' onClick={() => removeFromCart(template)}
                                    >Remove from cart</Button>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} className='mb-2'>
                                <Divider />
                            </Grid>
                        </Grid>
                    ))
                }
                <Grid xs={12} container item>
                    <Grid item xs={12} className='total-subtotal'>
                        <h4 className='mt-0'><span>Sub Total</span>{getPrice(total)}</h4>
                        <div>
                            <p className='mb-05'>Have a voucher code?</p>
                            <TextField placeholder='Enter Voucher Code' variant='outlined' className='voucher mb-1'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Button variant='contained' color='primary'
                                                // onClick={() => }
                                            >
                                                Apply
                                            </Button>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </div>
                        <h3><span>Total</span>{getPrice(total)}</h3>
                    </Grid>
                    <Grid item xs={12} container justifyContent='flex-end'>

                        <PaystackConsumer {...componentProps} amount={total * 100} email={userData.email} reference={reference} >
                            {
                                ({initializePayment}) => 
                                <Button disabled={total === 0} onClick={() =>  initializePayment(handleSuccess, handleClose)} 
                                    style={{ margin: '.5rem' }} variant='contained' color='primary'
                                >Complete Your Purchase</Button>
                            }
                        </PaystackConsumer>
                        
                        <Link to='/templates'>
                            <Button style={{ margin: '.5rem' }} variant='contained' color='secondary'>View other Templates</Button>
                        </Link>
                    </Grid>
                </Grid>
            </Grid>
        </AccountContainer>
    )
}

const mapStateToProps = ({ services, user }) => ({ services, user })

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ handleCart, handleWishList }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
