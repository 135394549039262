import { Container, Grid, Avatar, Button, IconButton, Divider, Drawer, TextField, LinearProgress } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useHistory } from 'react-router-dom'
import { ReactComponent as MenuIcon } from '../assets/images/menu-icon.svg'
import { connect } from 'react-redux'
import { signOutUser } from '../redux/actions/userActions'
import { bindActionCreators } from 'redux'
import { PRIMARY_COLOR } from '../styles/colors'
import { useMediaQuery } from 'react-responsive';
import { ExitToApp, Close, SettingsOutlined, ShoppingCartOutlined, PaymentOutlined, DescriptionOutlined, FormatListBulletedOutlined, PersonOutline, AccountBoxOutlined } from '@material-ui/icons';

const AdminContainer = ({ title = '', user, signOutAdmin, tabs = [], currentSection = '', children, processing = false }) => {
    const [ userData, setUserData ] = useState(user?.userData || {})
    const isTab = useMediaQuery({ query: '(max-width: 1000px)' })
    const [openMenu, setOpenMenu] = useState(false)
    const [section, setSection] = useState(currentSection)
    const history = useHistory()

    const links = [
        { name: 'Dashboard', url: '/dashboard', icon: <FormatListBulletedOutlined /> },
        { name: 'Orders', url: '/orders', icon: <DescriptionOutlined /> },
        { name: 'Resumes', url: '/resumes', icon: <AccountBoxOutlined /> },
        { name: 'Utilities', url: '/utilities', icon: <SettingsOutlined /> },
    ]

    useEffect(() => {
        setUserData(user?.userData || {})
    }, [user?.userData])

    useEffect(() => {
        setSection(currentSection)
    }, [currentSection])

    const signOut = async () => {
        await signOutUser()
    }

    const isActive = (link) => {
        return ( window.location.pathname.includes('/admin' + link)) ? 'active' : '' 
    }

    useEffect(() => {
        window.addEventListener('scroll', (e) => {
            const header = document?.querySelector('#header') || null
            const scroll = document.documentElement.scrollTop || document.body.scrollTop;
            if(header) {
                (scroll > 100) ? header?.setAttribute('style', "background-color: #AFD1FF") : header?.removeAttribute('style')
            }
        })
    }, [])

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
            <Helmet><title>{title && `${title} - `}Account | Retro Resume</title></Helmet>

            { processing && <LinearProgress /> }
            <Container className='container-padding'>
                <Grid container justifyContent={ isTab ? "space-between" :"center"} alignItems="center" spacing={3} className="pt-1 pb-1">
                    <Grid item>
                        <Link to="/"><h2 className="logo no-margin">Retro<span className="text-primary">Resume</span></h2></Link>
                    </Grid>

                    { !isTab ?
                        <>
                            <Grid item style={{ flex: 1 }} alignItems="center" container justifyContent="center" className="dashboard-menu">
                                {
                                    links.map(({ url, name, icon }, key) => (
                                        <Link to={'/admin'+url} key={key} className={isActive(url)} style={{ margin: '0 .5rem' }}>
                                            <Button startIcon={icon}>{name}</Button>
                                        </Link>
                                    ))
                                }
                            </Grid> 

                            <Grid item xs={2} container alignItems="center" justifyContent="center">
                                {/* <span>{userData?.displayName || ''}</span> */}
                                <Avatar src={userData?.photoURL || ''} />
                                <IconButton onClick={() => signOut()}><ExitToApp /></IconButton>
                            </Grid>
                        </>
                        :
                        <Grid item>
                            <IconButton onClick={() => setOpenMenu(true)}><MenuIcon /></IconButton>
                        </Grid> 
                    }
                </Grid>
            </Container>
            
            <div style={{ backgroundColor: PRIMARY_COLOR, padding: '1.5rem 0', color: '#fff' }}></div>

            <Container style={{ flex: 1 }} className='container-padding'>

                <Grid item xs={12}>
                    <h2>{title}</h2>
                </Grid>

                { children }                
            </Container>
            
            <footer>
                <Divider className="mt-5" />
                <p style={{ fontSize: '14px', padding: '0 1rem .5rem' }} className="text-center">Copyright © { new Date().getFullYear() } RetroResume. All rights reserved. <Link>Terms of Use</Link> | <Link>Privacy Policy</Link></p>
            </footer>

            <Drawer open={openMenu} anchor="right" onClose={() => setOpenMenu(false)}>
                <div className="d-flex f-column dashboard-menu" style={{ minWidth: '100vw', minHeight: '100vh', backgroundColor: '#E6EDF5', padding: '1rem 1.5rem' }}>
                    <div className="d-flex" style={{ justifyContent: 'space-between' }}>
                        <Link to="/"><h2 className="logo no-margin">Retro<span className="text-primary">Resume</span></h2></Link>
                        <Button disableRipple disableTouchRipple disableFocusRipple style={{ margin: 0, justifyContent: 'flex-end' }} onClick={()=> setOpenMenu(false)} ><Close /></Button>
                    </div>
                    <Grid container item direction="column" xs={12} className="mobile-menu">
                        {
                            links.map(({ url, name, icon }, key) => (
                                <Link to={'/admin'+url} key={key} className={isActive(url)}>
                                    <Button startIcon={icon} >{name}</Button>
                                </Link>
                            ))
                        }
                        <Link to="#">
                            <Button onClick={() => signOut()} startIcon={<ExitToApp />} >Sign Out</Button>
                        </Link>
                    </Grid>
                </div>
            </Drawer>
        </div>
    )
}

const mapStateToProps = ({ user }) => ({ user })

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ signOutUser }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminContainer)

