import React, { useEffect, useState } from 'react'
import { app } from '../redux/api/config' 
import { BrowserRouter, Route, Switch, useHistory, withRouter, Redirect, Link } from 'react-router-dom'
import Home from '../pages/Home';
import AOS from 'aos';
import Auth from '../pages/Auth';
import Templates from '../pages/Templates';
import About from '../pages/About';
import Contact from '../pages/Contact';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { signOutUser, updateUser } from '../redux/actions/userActions';
import { fetchTemplates, fetchUtilities } from '../redux/actions/serviceActions';
import AccountRoutes from './AccountRoutes.js'
import { Badge } from '@material-ui/core';
import { ArrowUpward, ShoppingCart } from '@material-ui/icons';
import $ from 'jquery'
import { getAuth } from "firebase/auth";
import SingleTemplate from '../pages/SingleTemplate';
import Loader from '../components/Loader';
import Terms from '../pages/Terms';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import AdminRoutes from './AdminRoutes';

const ScrollToTop = () => {
    const history = useHistory();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [history.location.pathname]);
  
    return null;
};

const ResetScroll = withRouter(ScrollToTop);

const Routes = ({ user, signOutUser, services, fetchTemplates, updateUser, fetchUtilities }) => {
    const [ authenticated, setAuthenticated ] =  useState(user.authenticated);
    const [ fetching, setFetching ] =  useState(false);
    const [cartList, setCartList ] = useState(services?.cartList || [])
    const history = useHistory();

    const scrollToTop = () => {
        $("html, body").animate({ scrollTop: 0 });
    }

    const initApp = async () => {
        try {
            setFetching(true)
            AOS.init()

            window.addEventListener('scroll', (e) => {
                const scrollElement = document.querySelector('#scrollToTop')
                const scroll = document.documentElement.scrollTop || document.body.scrollTop;
                if(scrollElement) {
                    (scroll > 200) ? scrollElement?.setAttribute('style', "display: inline-flex") : scrollElement?.removeAttribute('style')
                }
            })

            await fetchTemplates();
            await fetchUtilities();
            if(authenticated) await updateUser(user.userData.uid)
        }
        catch(err) {
            console.log(err)
        }
        finally {
            setFetching(false)
        }
    }

    useEffect(() => {
        initApp();
    }, [])

    useEffect(() => {
        setCartList(services?.cartList || [])
    }, [services])

    useEffect(() => {
        setAuthenticated(user.authenticated)
    }, [user])

    const PrivateRoute = ({ component: Component, exact, ...rest }) => {
        const auth = getAuth();
        useEffect(() => {
            if(!auth.currentUser) {
                signOutUser()
            }
        }, [auth])
        return (
          <Route
            {...rest}
            render={(props) =>
              authenticated ? <Component {...props} /> : <Redirect to="/sign-in" />
            }
            exact={exact}
          />
        );
    };

    return (
        <BrowserRouter>
            <ResetScroll />
            <Switch>
                <Route exact path="/home">
                    <Redirect to={`/`} />
                </Route>
                <Route component={Home} exact path="/" />
                <Route component={Auth} exact path="/sign-in" />
                <Route component={Auth} exact path="/sign-up" />
                <Route component={Auth} exact path="/forgot-password" />
                <Route exact path="/login">
                    <Redirect to={`/sign-in`} />
                </Route>
                <Route exact path="/register">
                    <Redirect to={`/sign-up`} />
                </Route>
                <Route exact path="/forget-password">
                    <Redirect to={`/forgot-password`} />
                </Route>
                <Route component={Templates} exact path="/templates" />
                <Route component={SingleTemplate} exact path="/template/:slug" />
                <Route component={Contact} exact path="/contact-us" />
                <Route component={About} exact path="/about-us" />
                <Route component={Terms} exact path="/terms-conditions" />
                <Route component={PrivacyPolicy} exact path="/privacy-policy" />

                <PrivateRoute component={AccountRoutes} path="/account" />
                <PrivateRoute component={AdminRoutes} path="/admin" />

                <Route path="**">
                    <Home />
                </Route>
            </Switch>
            {/* { Boolean(cartList.length > 0) && <Link className="cart-button" to='/account/cart'>
                <Badge badgeContent={cartList.length} color="error">
                    <ShoppingCart />
                </Badge>
            </Link> } */}
            { fetching && <Loader />}
            
            <Link to="#" className="scroll-to-top" id="scrollToTop" onClick={() => scrollToTop()}>
                <ArrowUpward />
            </Link>
        </BrowserRouter>
    )
}

const mapStateToProps = ({ user, services }) => ({ user, services })

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ signOutUser, fetchTemplates, updateUser, fetchUtilities }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
