import { Avatar, Button, Checkbox, FormControlLabel, Grid, IconButton, InputAdornment, TextField, CircularProgress } from '@material-ui/core'
import { Add, Check, CheckCircleOutline, Delete, Error, ErrorOutline } from '@material-ui/icons'
import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import AccountContainer from '../../components/Account/AccountContainer'
import authApi from '../../redux/api/auth'
import { updateUser } from '../../redux/actions/userActions'
import { bindActionCreators } from 'redux'
import { toast } from '../../util'
import $ from 'jquery';
import AccordionComponent from '../../components/Accordion'

const Profile = ({ user, updateUser }) => {
    const history = useHistory();
    const imageRef = useRef(null)
    const [ userData, setUserData ] = useState(user?.userData || {})
    const [ updating, setUpdating ] = useState(null)
    const [ userForm, setUserForm ] = useState({})
    const [ skills, setSkills ] = useState([])
    const [ educationalInformation, setEducationalInformation ] = useState([])
    const [ employmentInformation, setEmploymentInformation ] = useState([])

    const [ references, setReferences ] = useState([])
    const [ awards, setAwards ] = useState([])
    const [ languages, setLanguages ] = useState([])
    const [ hobbies, setHobbies ] = useState([])
    const [ socials, setSocials ] = useState([])
    const socialTypes = [
        'Facebook', 'Instagram', 'LinkedIn', 'TikTok', 'Twitter', 'WhatsApp', 'Twitch', 'YouTube', 'Telegram', 'Google+', 'Skype',
        'Zoom', 'WeChat', 'Tumblr', 'Viber', 'Snapchat', 'Pinterest', 'Reddit', 'Badoo', 'MySpace', 'Flickr', 'Quora', 'GitHub',
        'Discord', 'Triller', 'SoundCloud', 'Apple Music', 'Spotify', 'Medium', 'Vimeo', 'Stack overflow', 'Slack'
    ]
    
    const defEduInfo = { school: '', course: '', resultType: '', graduationYear: ''}
    const defEmpInfo = { employer: '', position: '', startDate: '', endDate: '', currentWork: false, description: ''}
    const defReference = { name: '', company: '', contact: '', position: ''}
    const defAward = { title: '', year: '', organization: '', description: ''}
    const defSkills = { name: '', rate: '' }
    const defSocial = { media: '', username: '' }
    const [ currentSection, setCurrentSection ] = useState('')
    
    const handleFormChange = ({ name, value }) => {
        setUserForm({ ...userForm, [name]: value });
    }
    
    const handleEducationFormChange = (key, { name, value }) => {
        let educationalInfo = [ ...educationalInformation];
        let form = educationalInfo[key];
        form[name] = value;
        educationalInfo[key] = form
        setEducationalInformation([ ...educationalInfo ]);
    }

    const handleEmploymentFormChange = (key, { name, value }) => {
        let employmentInfo = [ ...employmentInformation];
        let form = employmentInfo[key];
        form[name] = value;
        employmentInfo[key] = form
        setEmploymentInformation([ ...employmentInfo ]);
    }
    
    const handleSkillsChange = (key, { name, value }) => {
        let skillsRef = [ ...skills];
        let form = skillsRef[key];
        form[name] = value;
        skillsRef[key] = form
        setSkills([ ...skillsRef ]);
    }

    const handleReferencesChange = (key, { name, value }) => {
        let referencesRef = [ ...references];
        let form = referencesRef[key];
        form[name] = value;
        referencesRef[key] = form
        setReferences([ ...referencesRef ]);
    }

    const handleSocialsChange = (key, { name, value }) => {
        let socialRef = [ ...socials];
        let form = socialRef[key];
        form[name] = value;
        socialRef[key] = form
        setSocials([ ...socialRef ]);
    }

    const handleAwardsChange = (key, { name, value }) => {
        let awardRef = [ ...awards];
        let form = awardRef[key];
        form[name] = value;
        awardRef[key] = form
        setAwards([ ...awardRef ]);
    }

    const handleHobbiesChange = (key, { value }) => {
        let hobbiesRef = [ ...hobbies];
        hobbiesRef[key] = value;
        setHobbies([ ...hobbiesRef ]);
    }

    const handleLanguageChange = (key, { value }) => {
        let languageRef = [ ...languages];
        languageRef[key] = value;
        setLanguages([ ...languageRef ]);
    }

    const deleteInformation = (type, key) => {
        switch(type) {
            case 2:
                setEducationalInformation(educationalInformation.filter((edu, index) => index !== key))
                break;
            case 3:
                setEmploymentInformation(employmentInformation.filter((edu, index) => index !== key))
                break;
            case 4:
                setSkills(skills.filter((sk, index) => index !== key))
                break;
            case 5:
                setReferences(references.filter((ef, index) => index !== key))
                break;
            case 6:
                setHobbies(hobbies.filter((hb, index) => index !== key))
                break;
            case 7:
                setLanguages(languages.filter((lg, index) => index !== key))
                break;
            case 9:
                setAwards(awards.filter((lg, index) => index !== key))
                break;
            case 10:
                setSocials(socials.filter((lg, index) => index !== key))
                break;
            default:
                break;
        }
    }

    const handleImageChange = (e) => {
        if(e?.target?.files[0]) {
            updateUserInformation(8, e?.target?.files[0]);
        }
    }

    const baseUrl = '/account/profile';
    const tabs = [
        { name: 'Personal Information', url: baseUrl + '/personal-information' },
        { name: 'Educational Information', url: baseUrl + '/educational-information' },
        { name: 'Employment Information', url: baseUrl + '/employment-information' },
    ]

    const updateUserInformation = async (type, value) => {
        try {
            setUpdating(type)
            switch(type) {
                case 1:
                    await authApi.updateUserData(userData.uid, userForm);
                    break;
                case 2:
                    await authApi.updateUserData(userData.uid, { educationalInformation });
                    break;
                case 3:
                    await authApi.updateUserData(userData.uid, { employmentInformation });
                    break;
                case 4:
                    await authApi.updateUserData(userData.uid, { skills });
                    break;
                case 5:
                    await authApi.updateUserData(userData.uid, { references });
                    break;
                case 6:
                    await authApi.updateUserData(userData.uid, { hobbies });
                    break;
                case 7:
                    await authApi.updateUserData(userData.uid, { languages });
                    break;
                case 8:
                    await authApi.updateUserImage(userData.uid, value);
                    break;
                case 9: //awards
                    await authApi.updateUserData(userData.uid, { awards });
                    break;
                case 10: //awards
                    await authApi.updateUserData(userData.uid, { socials });
                    break;
                case 'all':
                    await authApi.updateUserData(userData.uid, { ...userForm, educationalInformation, employmentInformation, skills, references, hobbies, languages, socials, awards });
                    break;
                default:
                    break;
            }
            await updateUser(userData.uid);
            toast('Information Updated Successfully')
        }
        catch(err) {
            console.log(err);
        }
        finally {
            setUpdating(null)
        }
    }

    useEffect(() => {
        setUserData(user?.userData || {})
    }, [user])

    useEffect(() => {
        setUserForm({
            displayName: userData?.displayName || '',
            lastName: userData?.lastName || '',
            firstName: userData?.firstName || '',
            otherNames: userData?.otherNames || '',
            phoneNumber: userData?.phoneNumber || '',
            address: userData?.address || '',
            careerSummary: userData?.careerSummary || '',
            professionHeadline: userData?.professionHeadline || '',
        })
        setSkills(userData?.skills || [defSkills])
        setEducationalInformation(userData?.educationalInformation || [defEduInfo])
        setEmploymentInformation(userData?.employmentInformation || [defEmpInfo])
        setReferences(userData?.references || [defReference])
        setAwards(userData?.awards || [])
        setSocials(userData?.socials || [defSocial])
        setHobbies(userData?.hobbies || [''])
        setLanguages(userData?.languages || [''])
    }, [userData])
    
    useEffect(() => {
        setCurrentSection(history?.location?.pathname || baseUrl + '/personal-information')
    }, [history.location.pathname])

    const  [ details, setDetails ] = useState([])
    useEffect(() => {
        setDetails({
            "Personal Information": Boolean(userData?.firstName && userData?.lastName && userData?.displayName && userData?.address && userData?.phoneNumber && userData?.careerSummary && userData?.professionHeadline && userData?.email && userData?.photoURL),
            "Educational Information": Boolean(userData?.educationalInformation?.length > 0),
            "Employment Information": Boolean(userData?.employmentInformation?.length > 0),
            "Skills": Boolean(userData?.skills?.length > 0),
            "Hobbies": Boolean(userData?.hobbies?.length > 0),
            "References": Boolean(userData?.references?.length > 0),
            "Languages": Boolean(userData?.languages?.length > 0),
            "Awards": Boolean(userData?.awards?.length > 0),
            "Socials": Boolean(userData?.socials?.length > 0)
        })
    }, [userData])

    const goto = (section) => {
        $('html, body').animate({
            scrollTop: $(`#${section}`).offset().top
        }, 1000);
    }

    return (
        <AccountContainer title="" currentSection={currentSection}>
            <Grid container item className="no-margin profile" style={{ width: '100%' }}>
                {
                    Object?.values(details).length > 0 && !Object?.values(details)?.reduce((prev, current) => prev && current) &&    
                    <Grid item xs={12}>
                        <Grid container item xs={12} className="card no-margin" spacing={3}>
                            <Grid item xs={12}>
                                <h3>Complete Profile</h3>
                            </Grid>
                            {
                                Object.keys(details).map((name, key) => (
                                    name !== "Awards" &&
                                    <Grid item xs={12} md={6} lg={4} key={key}>
                                        <Button
                                            onClick={() => goto(name.toLowerCase().replaceAll(' ', '-'))}
                                            variant="outlined" className={details[name] ? 'success-btn-outline' : 'error-btn-outline'}
                                            fullWidth style={{ textAlign: 'left' }}
                                            startIcon={details[name] ? 
                                                <CheckCircleOutline /> : 
                                                <ErrorOutline /> 
                                            }
                                        >{name}</Button>
                                    </Grid> 
                                ))
                            }
                        </Grid>
                    </Grid>
                }

                <Grid item xs={12} id="personal-information">
                    <Grid container item xs={12} className="card no-margin" spacing={3}>
                        <AccordionComponent title='Personal Information'>
                            <Grid container spacing={3}>

                                <Grid item xs={12}>
                                    <h3>Personal Information</h3>
                                </Grid>
                                <Grid item xs={12} container direction="column" alignItems="center">
                                    <Avatar 
                                        src={userData?.photoURL ? typeof(userData?.photoURL) === 'string' ? userData?.photoURL : URL.createObjectURL(userData?.photoURL) : '' } 
                                        variant="rounded" style={{ width: '250px', height: '250px' }} 
                                    />
                                    <small style={{ textAlign: 'center', display: 'block', marginTop: '1rem' }}>
                                        Kindly upload a high resolution image for best quality
                                    </small>
                                    <input hidden ref={imageRef} onChange={handleImageChange} type='file' accept="images/*" />
                                    <Button onClick={() => imageRef.current.click()} variant="contained" color="primary" className='mt-1 mb-05'>
                                        { (updating === 8) ? <CircularProgress color='inherit' size={15} /> : "Upload Photo" }
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <TextField value={userForm?.professionHeadline} label="Professional Headline" placeholder="Ex. Software Engineer" 
                                        onChange={(e) => handleFormChange(e.target)} name="professionHeadline" variant="outlined"
                                        InputLabelProps={{ shrink: true }} fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <TextField value={userForm?.displayName} label="Display Name" placeholder="Enter display name" 
                                        onChange={(e) => handleFormChange(e.target)} name="displayName" variant="outlined"
                                        InputLabelProps={{ shrink: true }} fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <TextField value={userForm?.lastName} label="Last Name" placeholder="Enter last name" 
                                        onChange={(e) => handleFormChange(e.target)} name="lastName" variant="outlined"
                                        InputLabelProps={{ shrink: true }} fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <TextField value={userForm?.firstName} label="First Name" placeholder="Enter first name" 
                                        onChange={(e) => handleFormChange(e.target)} name="firstName" variant="outlined"
                                        InputLabelProps={{ shrink: true }} fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <TextField value={userForm?.otherNames} label="Other Name(s)" placeholder="Enter other name(s)" 
                                        onChange={(e) => handleFormChange(e.target)} name="otherNames" variant="outlined"
                                        InputLabelProps={{ shrink: true }} fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <TextField value={userData?.email} label="Email address" placeholder="Enter email address" 
                                        disabled name="email" variant="outlined"
                                        InputLabelProps={{ shrink: true }} fullWidth type="email"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <TextField value={userForm?.phoneNumber} label="Phone Number" placeholder="Enter phone number" 
                                        onChange={(e) => handleFormChange(e.target)} name="phoneNumber" variant="outlined"
                                        InputLabelProps={{ shrink: true }} fullWidth  type='tel'
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <TextField value={userForm?.phoneNumber2} label="Second Phone Number" placeholder="Enter phone number (optional)" 
                                        onChange={(e) => handleFormChange(e.target)} name="phoneNumber2" variant="outlined"
                                        InputLabelProps={{ shrink: true }} fullWidth type='tel'
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField value={userForm?.address} label="Home Address" placeholder="Enter address" 
                                        onChange={(e) => handleFormChange(e.target)} name="address" variant="outlined"
                                        InputLabelProps={{ shrink: true }} fullWidth
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <h3>Career Summary</h3>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField value={userForm?.careerSummary} label="Career Summary" placeholder="Enter career summary"
                                        onChange={(e) => handleFormChange(e.target)} name="careerSummary" variant="outlined"
                                        InputLabelProps={{ shrink: true }} fullWidth multiline minRows={5}
                                    />
                                </Grid>
                                <Grid item xs={12} container justifyContent='center'>
                                    <Button variant="contained" color="primary" className="mt-1 mb-1" onClick={() => updateUserInformation(1)}>
                                        { (updating === 1) ? <CircularProgress color='inherit' size={15} /> : "Save Personal Information" }
                                    </Button>
                                </Grid>
                            </Grid>
                        </AccordionComponent>

                    </Grid>
                </Grid>

                <Grid item xs={12} id="educational-information">
                    <Grid container item xs={12} className="card no-margin" spacing={3}>
                        <Grid item xs={12}>
                            <h3>Educational Information</h3>
                        </Grid>
                        <Grid item xs={12}>
                            {
                                educationalInformation.map((info, key) => (
                                    <AccordionComponent  id={`edu${key}`} key={key} title={`${key + 1}. ${info.school || 'Untitled'}  ${info?.course && '(' +info?.course + ')' }`}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <TextField value={info?.school} label="Name of school" placeholder="Enter school name" 
                                                    onChange={(e) => handleEducationFormChange(key, e.target)} name="school" variant="outlined"
                                                    InputLabelProps={{ shrink: true }} fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={4}>
                                                <TextField value={info?.course} label="Course" placeholder="Enter course" 
                                                    onChange={(e) => handleEducationFormChange(key, e.target)} name="course" variant="outlined"
                                                    InputLabelProps={{ shrink: true }} fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={4}>
                                                <TextField value={info?.resultType} label="Result Type" placeholder="Enter result type" 
                                                    onChange={(e) => handleEducationFormChange(key, e.target)} name="resultType" variant="outlined"
                                                    InputLabelProps={{ shrink: true }} fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={4}>
                                                <TextField value={info?.graduationYear} label="Year of Graduation"
                                                    onChange={(e) => handleEducationFormChange(key, e.target)} name="graduationYear" variant="outlined"
                                                    InputLabelProps={{ shrink: true }} fullWidth type="date"
                                                />
                                            </Grid>
                                            {
                                                educationalInformation.length > 1 &&
                                                <Grid item xs={12} md={6} lg={4} style={{ marginBottom: '2rem' }}>
                                                    <Button onClick={() => deleteInformation(2, key)} variant='contained' className='error-btn' fullWidth startIcon={<Delete />}>Delete</Button>
                                                </Grid>
                                            }
                                        </Grid>
                                    </AccordionComponent>
                                ))
                            }
                        </Grid>
                        <Grid item xs={12} container justifyContent="flex-end">
                            <Button color="secondary" variant="contained" startIcon={<Add />}
                                onClick={() => setEducationalInformation([ ...educationalInformation, defEduInfo])}
                            >Add Educational Information</Button>
                        </Grid>
                        <Grid item xs={12} container justifyContent="center">
                            <Button color="primary" variant="contained" onClick={() => updateUserInformation(2)}>
                                { (updating === 2) ? <CircularProgress color='inherit' size={15} /> : "Save Educational Information" }    
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} id="employment-information">
                    <Grid container item xs={12} className="card no-margin" spacing={3}>
                        <Grid item xs={12}>
                            <h3>Employment Information</h3>
                        </Grid>
                        {
                            employmentInformation.map((info, key) => (
                                <AccordionComponent  id={`emp${key}`} key={key} title={`${key + 1}. ${info.employer || 'Untitled'}  ${info?.position && '(' +info?.position + ')' }`}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <TextField value={info?.employer} label="Name of employer" placeholder="Enter employer name" 
                                                onChange={(e) => handleEmploymentFormChange(key, e.target)} name="employer" variant="outlined"
                                                InputLabelProps={{ shrink: true }} fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <TextField value={info?.position} label="Position" placeholder="Enter position" 
                                                onChange={(e) => handleEmploymentFormChange(key, e.target)} name="position" variant="outlined"
                                                InputLabelProps={{ shrink: true }} fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <TextField value={info?.startDate} label="Work start date"
                                                onChange={(e) => handleEmploymentFormChange(key, e.target)} name="startDate" variant="outlined"
                                                InputLabelProps={{ shrink: true }} fullWidth type="date"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <TextField value={info?.endDate} label="Work end date"
                                                onChange={(e) => handleEmploymentFormChange(key, e.target)} name="endDate" variant="outlined"
                                                InputLabelProps={{ shrink: true }} fullWidth type="date"
                                            />
                                            <FormControlLabel className="no-margin"
                                                control={<Checkbox checked={info.currentWork} name="currentWork" />}
                                                label="I currently work here" onClick={() => handleEmploymentFormChange(key, { name: 'currentWork', value: !info.currentWork})}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField value={info?.description} label="Job Description" placeholder="Enter description" 
                                                onChange={(e) => handleEmploymentFormChange(key, e.target)} name="description" variant="outlined"
                                                InputLabelProps={{ shrink: true }} fullWidth multiline minRows={4}
                                            />
                                        </Grid>
                                        {
                                            employmentInformation.length > 1 &&
                                            <Grid item xs={12} md={6} lg={4} style={{ marginBottom: '2rem' }}>
                                                <Button onClick={() => deleteInformation(3, key)} variant='contained' className='error-btn' fullWidth startIcon={<Delete />}>Delete</Button>
                                            </Grid>
                                        }
                                    </Grid>
                                </AccordionComponent>
                            ))
                        }
                        <Grid item xs={12} container justifyContent="flex-end">
                            <Button color="secondary" startIcon={<Add />} variant="contained" 
                                onClick={() => setEmploymentInformation([ ...employmentInformation, defEmpInfo])}
                            >
                                Add Employment Information
                            </Button>
                        </Grid>
                        <Grid item xs={12} container justifyContent="center">
                            <Button variant="contained" color="primary" className="mt-1 mb-1" onClick={() => updateUserInformation(3)}>
                                { (updating === 3) ? <CircularProgress color='inherit' size={15} /> : "Save Employment Information" }
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} id="skills">
                    <Grid container item xs={12} className="card no-margin" spacing={3}>
                        <Grid item xs={12}>
                            <h3>Skills</h3>
                        </Grid>
                        <Grid item xs={12}>
                            { skills.map((skill, key) => (
                                <AccordionComponent  id={`skill${key}`} key={key} title={`${key + 1}. ${skill.name || 'Untitled'}  ${skill?.rate && '(' +skill?.rate + ')' }`}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField label="Skill" placeholder="Enter skill"
                                                    name="name" variant="outlined" onChange={(e) => handleSkillsChange(key, e.target)}
                                                InputLabelProps={{ shrink: true }} fullWidth value={skill.name}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField fullWidth name="rate" variant='outlined' select SelectProps={{ native: true }} InputLabelProps={{ shrink: true }}
                                                onChange={(e) => handleSkillsChange(key, e.target)} value={skill.rate}
                                            >
                                                <option value="">Select</option>
                                                {
                                                    [...new Array(10).fill('')].map((v, key) => (
                                                        <option key={key} value={`${key+1}0`}>{key+1}0%</option>
                                                    ))
                                                }
                                            </TextField>
                                        </Grid>
                                        {
                                            skills.length > 1 && <>
                                                <Grid item xs={12} md={6} lg={4} style={{ marginBottom: '2rem' }}>
                                                    <Button onClick={() => deleteInformation(4, key)} variant='contained' className='error-btn' fullWidth startIcon={<Delete />}>Delete</Button>
                                                </Grid>
                                                <Grid item xs={12} className='no-padding'></Grid>
                                            </>
                                        }
                                    </Grid>
                                </AccordionComponent>
                            ))}
                        </Grid>

                        <Grid item xs={12} container justifyContent="flex-end">
                            <Button color="secondary" variant="contained" startIcon={<Add />}
                                onClick={() => setSkills([ ...skills, defSkills])}
                            >Add new skill</Button>
                        </Grid>
                        <Grid item xs={12} container justifyContent='center'>
                            <Button variant="contained" color="primary" className="mt-1 mb-1" onClick={() => updateUserInformation(4)}>
                                { (updating === 4) ? <CircularProgress color='inherit' size={15} /> : "Save Skills Information" }
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} id="references">
                    <Grid container item xs={12} className="card no-margin" spacing={3}>
                        <Grid item xs={12}>
                            <h3>References</h3>
                        </Grid>
                        <Grid item xs={12}>
                            {
                                references.map((ref, key) => (
                                    <AccordionComponent  id={`ref${key}`} key={key} title={`${key + 1}. ${ref.name || 'Untitled'}`}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={6} lg={4}>
                                                <TextField value={ref?.name} label="Full name" placeholder="Enter reference name" 
                                                    onChange={(e) => handleReferencesChange(key, e.target)} name="name" variant="outlined"
                                                    InputLabelProps={{ shrink: true }} fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={4}>
                                                <TextField value={ref?.contact} label="Contact" placeholder="Enter Phone Number or Address" 
                                                    onChange={(e) => handleReferencesChange(key, e.target)} name="contact" variant="outlined"
                                                    InputLabelProps={{ shrink: true }} fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={4}>
                                                <TextField value={ref?.company} label="Company"
                                                    onChange={(e) => handleReferencesChange(key, e.target)} name="company" variant="outlined"
                                                    InputLabelProps={{ shrink: true }} fullWidth placeholder="Enter reference place of work"
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={4}>
                                                <TextField value={ref?.position} label="Position in Company"
                                                    onChange={(e) => handleReferencesChange(key, e.target)} name="position" variant="outlined"
                                                    InputLabelProps={{ shrink: true }} fullWidth  placeholder="Enter reference position in place of work"
                                                />
                                            </Grid>
                                            {
                                                references.length > 1 && <>
                                                    <Grid item xs={12} md={6} lg={4} style={{ marginBottom: '2rem' }}>
                                                        <Button onClick={() => deleteInformation(5, key)} variant='contained' className='error-btn' fullWidth startIcon={<Delete />}>Delete</Button>
                                                    </Grid>
                                                    <Grid item xs={12} className='no-padding'></Grid> 
                                                </>
                                            }
                                        </Grid>
                                    </AccordionComponent>
                                ))
                            }
                        </Grid>
                        <Grid item xs={12} container justifyContent="flex-end">
                            <Button color="secondary" startIcon={<Add />} variant="contained" 
                                onClick={() => setReferences([ ...references, defReference])}
                            >
                                Add Reference
                            </Button>
                        </Grid>
                        <Grid item xs={12} container justifyContent="center">
                            <Button variant="contained" color="primary" className="mt-1 mb-1" onClick={() => updateUserInformation(5)}>
                                { (updating === 5) ? <CircularProgress color='inherit' size={15} /> : "Save References" }
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} id="hobbies">
                    <Grid container item xs={12} className="card no-margin" spacing={3}>
                        <AccordionComponent title='Hobbies/Interests' style={{ width: '100%' }}>
                            <Grid container spacing={3}>      
                                <Grid item xs={12}>
                                    <h3>Hobbies/Interests</h3>
                                </Grid>
                                { hobbies.map((hobby, key) => (
                                    <Grid item xs={12} sm={6} key={key}>
                                        <TextField value={hobby} label="Hobby" placeholder="Enter Hobby"
                                            name="hobby" variant="outlined" onChange={(e) => handleHobbiesChange(key, e.target)}
                                            InputLabelProps={{ shrink: true }} fullWidth
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            className="no-margin error-btn"
                                                            onClick={() => deleteInformation(6, key)}
                                                            edge="end" style={{ borderRadius: 5 }}
                                                        >
                                                            <Delete />
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                ))}

                                <Grid item xs={12} container justifyContent="flex-end">
                                    <Button color="secondary" variant="contained" startIcon={<Add />}
                                        onClick={() => setHobbies([ ...hobbies, ''])}
                                    >Add new hobby</Button>
                                </Grid>
                                <Grid item xs={12} container justifyContent='center'>
                                    <Button variant="contained" color="primary" className="mt-1 mb-1" onClick={() => updateUserInformation(6)}>
                                        { (updating === 6) ? <CircularProgress color='inherit' size={15} /> : "Save Hobbies" }
                                    </Button>
                                </Grid>
                            </Grid>
                        </AccordionComponent>
                    </Grid>
                </Grid>

                <Grid item xs={12} id="languages">
                    <Grid container item xs={12} className="card no-margin" spacing={3}>
                        <AccordionComponent title='Languages' style={{ width: '100%' }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <h3>Languages</h3>
                                </Grid>
                                { languages.map((language, key) => (
                                    <Grid item xs={12} sm={6} key={key}>
                                        <TextField value={language} label="language" placeholder="Enter Language"
                                            name="language" variant="outlined" onChange={(e) => handleLanguageChange(key, e.target)}
                                            InputLabelProps={{ shrink: true }} fullWidth
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            className="no-margin" className='error-btn' 
                                                            onClick={() => deleteInformation(7, key)}
                                                            edge="end" style={{ borderRadius: 5 }}
                                                        >
                                                            <Delete />
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                ))}

                                <Grid item xs={12} container justifyContent="flex-end">
                                    <Button color="secondary" variant="contained" startIcon={<Add />}
                                        onClick={() => setLanguages([ ...languages, ''])}
                                    >Add new language</Button>
                                </Grid>
                                <Grid item xs={12} container justifyContent='center'>
                                    <Button variant="contained" color="primary" className="mt-1 mb-1" onClick={() => updateUserInformation(7)}>
                                        { (updating === 7) ? <CircularProgress color='inherit' size={15} /> : "Save Languages" }
                                    </Button>
                                </Grid>
                            </Grid>
                        </AccordionComponent>
                    </Grid>
                </Grid>

                <Grid item xs={12} id="awards">
                    <Grid container item xs={12} className="card no-margin" spacing={3}>
                        <Grid item xs={12}>
                            <h3>Awards (Optional)</h3>
                        </Grid>
                        <Grid item xs={12}>
                            {
                                awards.map((award, key) => (
                                    <AccordionComponent  id={`award${key}`} key={key} title={`${key + 1}. ${award.title || 'Untitled'}`}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={6} lg={4}>
                                                <TextField value={award?.title} label="Title" placeholder="Enter award title" 
                                                    onChange={(e) => handleAwardsChange(key, e.target)} name="title" variant="outlined"
                                                    InputLabelProps={{ shrink: true }} fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={4}>
                                                <TextField value={award?.year} label="Date" placeholder="Enter award date" 
                                                    onChange={(e) => handleAwardsChange(key, e.target)} name="date" variant="outlined"
                                                    InputLabelProps={{ shrink: true }} fullWidth type='date'
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={4}>
                                                <TextField value={award?.organization} label="Organization"
                                                    onChange={(e) => handleAwardsChange(key, e.target)} name="organization" variant="outlined"
                                                    InputLabelProps={{ shrink: true }} fullWidth placeholder="Enter organization"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField value={award?.description} label="Description"
                                                    onChange={(e) => handleAwardsChange(key, e.target)} name="description" variant="outlined"
                                                    InputLabelProps={{ shrink: true }} fullWidth  placeholder="Enter description" multiline
                                                />
                                            </Grid>
                                            {
                                                awards.length > 1 && <>
                                                    <Grid item xs={12} md={6} lg={4} style={{ marginBottom: '2rem' }}>
                                                        <Button onClick={() => deleteInformation(9, key)} variant='contained' className='error-btn' fullWidth startIcon={<Delete />}>Delete</Button>
                                                    </Grid>
                                                    <Grid item xs={12} className='no-padding'></Grid> 
                                                </>
                                            }
                                        </Grid>
                                    </AccordionComponent>
                                ))
                            }
                        </Grid>
                        <Grid item xs={12} container justifyContent="flex-end">
                            <Button color="secondary" startIcon={<Add />} variant="contained" 
                                onClick={() => setAwards([ ...awards, defAward])}
                            >
                                Add Award
                            </Button>
                        </Grid>
                        <Grid item xs={12} container justifyContent="center">
                            <Button variant="contained" color="primary" className="mt-1 mb-1" onClick={() => updateUserInformation(9)}>
                                { (updating === 9) ? <CircularProgress color='inherit' size={15} /> : "Save Awards" }
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} id="socials">
                    <Grid container item xs={12} className="card no-margin" spacing={3}>
                        <Grid item xs={12}>
                            <h3>Social Media Contact</h3>
                        </Grid>
                        <Grid item xs={12}>
                            {
                                socials.map((social, key) => (
                                    <AccordionComponent key={key} id={`social${key}`} title={`${key + 1}. ${social.media || 'Untitled'}`}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={6} lg={4}>
                                                <TextField fullWidth name="media" variant='outlined' select SelectProps={{ native: true }} InputLabelProps={{ shrink: true }}
                                                    onChange={(e) => handleSocialsChange(key, e.target)} value={social.media}
                                                >
                                                    <option value="">Select</option>
                                                    {
                                                        socialTypes.sort().map((s, key) => (
                                                            <option key={key} value={s}>{s}</option>
                                                        ))
                                                    }
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={4}>
                                                <TextField value={social?.username} label="Username" placeholder="Enter username" 
                                                    onChange={(e) => handleSocialsChange(key, e.target)} name="username" variant="outlined"
                                                    InputLabelProps={{ shrink: true }} fullWidth
                                                />
                                            </Grid>
                                            {
                                                socials.length > 1 && <>
                                                    <Grid item xs={12} md={6} lg={4} style={{ marginBottom: '2rem' }}>
                                                        <Button onClick={() => deleteInformation(10, key)} variant='contained' className='error-btn' fullWidth startIcon={<Delete />}>Delete</Button>
                                                    </Grid>
                                                    <Grid item xs={12} className='no-padding'></Grid> 
                                                </>
                                            }
                                        </Grid>
                                    </AccordionComponent>
                                ))
                            }
                        </Grid>
                        <Grid item xs={12} container justifyContent="flex-end">
                            <Button color="secondary" startIcon={<Add />} variant="contained" 
                                onClick={() => setSocials([ ...socials, defSocial])}
                            >
                                Add Social Contact
                            </Button>
                        </Grid>
                        <Grid item xs={12} container justifyContent="center">
                            <Button variant="contained" color="primary" className="mt-1 mb-1" onClick={() => updateUserInformation(10)}>
                                { (updating === 10) ? <CircularProgress color='inherit' size={15} /> : "Save Socials" }
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </AccountContainer>
    )
}

const mapStateToProps = ({ user }) => ({ user })

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ updateUser }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
