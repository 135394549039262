import { LinearProgress } from '@material-ui/core'
import React from 'react'

const Loader = () => {
    return (
        <div className="loader">
            {/* <img src={Logo} /> */}
            <h2 className="logo no-margin" style={{ fontSize: 25 }}>Retro<span className="text-primary">Resume</span></h2>
            <p className='text-center mt-0' style={{ fontSize: 14 }}>Effortlessly make a job-worthy resume </p>
            <LinearProgress color="primary" />
            <div className='loader-bg'/>
        </div>
    )
}

export default Loader