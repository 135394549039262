import { Accordion as MuiAccordion, AccordionDetails as MuiAccordionDetails, AccordionSummary as MuiAccordionSummary } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import { ArrowForwardIosSharp } from '@material-ui/icons'
import React, { useState } from 'react'
import { SECONDARY_COLOR, PRIMARY_LIGHT_COLOR } from '../styles/colors';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '10px',
    margin: '1rem 0',
    boxShadow: '0px 25px 50px rgba(22, 25, 79, 0.05)',
    // '&:not(:last-child)': {
    //     borderBottom: 0,
    // },
    '&:before': {
        display: 'none',
    },
}));
  
const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharp style={{ fontSize: '0.9rem', color: SECONDARY_COLOR }} />}
        {...props}
    />
))(({ theme }) => ({
        borderColor: PRIMARY_LIGHT_COLOR,
        color: SECONDARY_COLOR,
        fontSize: 15, fontWeight: 'bold',
        minHeight: '64px',
        cursor: 'pointer',
        // flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
        '& .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(1),
        },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));


const AccordionComponent = ({ items = null, children, id = '', detailsStyle = {}, title = '', ...others }) => {
    const [expanded, setExpanded] = useState(0);

    const handleChange = (panel) => (ev, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <>
            {
                Boolean(items) ?
                items?.map(({ title, body }, key) => (
                    <Accordion key={key} expanded={expanded === key} onChange={handleChange(key)} className='custom-accordion' {...others}>
                        <AccordionSummary className='summary'>{key + 1}. {title}</AccordionSummary>
                        <AccordionDetails className='details'>
                            <p className='no-margin' dangerouslySetInnerHTML={{ __html: body }} />
                        </AccordionDetails>
                    </Accordion>
                ))
                :
                <Accordion expanded={expanded === id} onChange={handleChange(id)} className='custom-accordion' {...others}>
                    <AccordionSummary className='summary'>{title}</AccordionSummary>
                    <AccordionDetails className='details'>
                        {children}
                    </AccordionDetails>
                </Accordion>
            }
        </>
    )
}

export default AccordionComponent