import { Grid, Button } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import AdminContainer from '../../components/AdminContainer'
import { People, DescriptionOutlined, ListAlt, CheckBoxOutlined, Refresh } from '@material-ui/icons';
import templateApi from '../../redux/api/template';
import { useMediaQuery } from 'react-responsive';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchAnalytics } from '../../redux/actions/serviceActions';

const Dashboard = ({ services, fetchAnalytics }) => {
    const sm = useMediaQuery({ query: '(min-width: 500px)' })
    const [ processing, setProcessing ] = useState(false)
    const [ analytics, setAnalytics ] = useState(services?.analytics)
    const [ dashboardAnalytics, setDashboardAnalytics ] = useState([])

    useEffect(() => {
        setAnalytics(services?.analytics || {});
    }, [services?.analytics])

    const refresh = async () => {
        try {
            setProcessing(true)
            await fetchAnalytics();
        }
        catch (err) {
            console.log(err)
        }
        finally {
            setProcessing(false)
        }
    }

    useEffect(() => {
        setDashboardAnalytics([
            { icon: <People/>, title: 'Users', data: analytics?.users || 0 },
            { icon: <DescriptionOutlined />, title: 'Templates', data: analytics?.templates || 0},
            { icon: <ListAlt/>, title: 'Orders', data: analytics?.orders || 0},
            { icon: <ListAlt/>, title: 'Orders Amount', data: analytics?.ordersTotal || 0},
            { icon: <CheckBoxOutlined />, title: 'Purchased Templates', data: analytics?.purchasedTemplates || 0},
        ])
    }, [analytics])

    return (
        <AdminContainer title="Dashboard" processing={processing}>
            <Grid container alignItems="center" spacing={3}>
                <Grid item xs={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <Button fullWidth={!Boolean(sm)} style={{ marginTop: sm ? '-3.5rem' : 0, height: '40px' }}
                        variant="contained" color="primary" onClick={() => refresh()} startIcon={<Refresh />}
                    >
                        Refresh
                    </Button>
                </Grid>
                {
                    dashboardAnalytics?.map((analytic, key) => (
                        <Grid item key={key} xs={12} md={3}>
                            <Grid item xs={12} container alignItems="center" className="dashboard-card">
                                <Grid item xs={2} container justifyContent='center' alignItems='center'>
                                    {analytic.icon}
                                </Grid>
                                <Grid item xs={10} className="no-margin pl-05">
                                    <h3 className='no-margin'><b>{ analytic.data }</b></h3>
                                    <p className='no-margin'>{ analytic.title }</p>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))
                }
            </Grid>
        </AdminContainer>
    )
}

const mapStateToProps = ({ services }) => ({ services })

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ fetchAnalytics }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);