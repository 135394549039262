import { Button, Container, Drawer, Grid } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import MenuIcon from '../assets/images/menu-icon.svg'
import { LinearProgress } from '@material-ui/core'
import { Close, Facebook, Instagram, LinkedIn, Twitter, WhatsApp } from '@material-ui/icons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { signOutUser } from '../redux/actions/userActions'
import Loader from './Loader';

const Header = ({ processing, user, signOutUser, secondaryLoader }) => {
    const isTab = useMediaQuery({ query: '(max-width: 1010px)' })
    const isSmallerTab = useMediaQuery({ query: '(max-width: 900px)' })
    const isPhone = useMediaQuery({ query: '(max-width: 700px)' })
    const [openMenu, setOpenMenu] = useState(false)
    const links = [
        { label: 'Home', link: '/' },
        { label: 'Templates', link: '/templates' },
        { label: 'About Us', link: '/about-us' },
        { label: 'Contact Us', link: '/contact-us' },
        // { label: 'Dashboard', link: '/account/dashboard' },
    ]
    const [ loggedIn, setLoggedIn ] = useState(user.authenticated)

    const isActive = (link) => {
        return (link === window.location.pathname) ? 'active' : '' 
    }

    
    const signOut = async () => {
        await signOutUser()
    }

    useEffect(() => {
        window.addEventListener('scroll', (e) => {
            const header = document?.querySelector('#header') || null
            const scroll = document.documentElement.scrollTop || document.body.scrollTop;
            if(header) {
                (scroll > 10) ? header?.setAttribute('style', "background-color: #AFD1FF") : header?.removeAttribute('style')
            }
        })
    }, [])

    useEffect(() => {
        setLoggedIn(user.authenticated)
    }, [user.authenticated])

    return (
        <header id="header">
            { processing && <>{secondaryLoader ? <Loader/> : <LinearProgress /> }</>}
            <Container style={{ padding: !isTab ? '0 5%' : '0 3.5%' }}>
                <Grid container alignItems="center" className="mt-1 mb-1">
                    <Grid item xs={10} sm={6} md={2}>
                        <Link to="/"><h2 className="logo no-margin">Retro<span className="text-primary">Resume</span></h2></Link>
                    </Grid>
                    { !isTab && <Grid item md={7} justifyContent="center" container>
                        <nav>
                            {
                                links.map(({link, label}, key) => (
                                    <Link key={key} className={isActive(link)} to={link}>{label}</Link>
                                ))
                            }
                        </nav>
                    </Grid> }
                    <Grid item md={isTab ? 10 : 3} sm={6} xs={2} container justifyContent={ isTab ? 'flex-end' : 'center'} className="btns">
                        {
                            loggedIn ?
                            <>
                                { !isPhone && <Link to="/account/profile"><Button variant="contained" color="primary">Account</Button></Link> }
                                { !isSmallerTab && <Button onClick={() => signOut()} variant="contained" color="secondary">Sign out</Button> }
                            </>
                            :
                            <>
                                { !isPhone && <Link to="/sign-in"><Button variant="contained" color="primary">Sign In</Button></Link> }
                                { !isSmallerTab && <Link to="/sign-up"><Button variant="contained" color="secondary">Sign up</Button></Link> }
                            </>
                        }
                        { isTab && <Button disableRipple disableTouchRipple disableFocusRipple style={{ margin: 0, justifyContent: 'flex-end', minWidth : 0 }} onClick={() => setOpenMenu(true)}><img src={MenuIcon} width="22" alt="menu" /></Button>}
                    </Grid>
                </Grid>
            </Container>

            <Drawer open={openMenu} anchor="right" onClose={() => setOpenMenu(false)}>
                <div className="d-flex f-column" style={{ minWidth: '100vw', minHeight: '100vh', backgroundColor: '#E6EDF5', padding: '1rem 1.5rem' }}>
                    <div className="d-flex" style={{ justifyContent: 'space-between' }}>
                        <Link to="/"><h2 className="logo no-margin">Retro<span className="text-primary">Resume</span></h2></Link>
                        <Button disableRipple disableTouchRipple disableFocusRipple style={{ margin: 0, justifyContent: 'flex-end', minWidth: 0 }} onClick={()=> setOpenMenu(false)} ><Close /></Button>
                    </div>
                    <Grid container item direction="column" xs={12} className="mobile-menu">
                        {
                            links.map(({link, label}, key) => (
                                <Link style={{ margin: '.8rem auto' }} onClick={()=> setOpenMenu(false)} key={key} className={isActive(link)} to={link}>{label}</Link>
                            ))
                        }

                        {
                            loggedIn ?
                            <>
                                <Link onClick={()=> setOpenMenu(false)} className="btn" to="/account/profile"><Button fullWidth variant="contained" color="primary">Account</Button></Link>
        
                                <Link onClick={()=> { setOpenMenu(false); signOut() }} className="btn" to="#"><Button fullWidth variant="contained" color="secondary">Sign out</Button></Link>
                            </>
                            :
                            <>
                                <Link onClick={()=> setOpenMenu(false)} className="btn" to="/sign-in"><Button fullWidth variant="contained" color="primary">Sign In</Button></Link>
        
                                <Link onClick={()=> setOpenMenu(false)} className="btn" to="/sign-up"><Button fullWidth variant="contained" color="secondary">Sign up</Button></Link>
                            </>
                        }

                        <div className="socials">
                            <a target="_blank" href="https://instagram.com/retro_resume"><Instagram /></a>
                            <a target="_blank" href="https://twitter.com/retro_resume"><Twitter /></a>
                            <a target="_blank" href="https://www.linkedin.com/company/79996601/"><LinkedIn /></a>
                            <a target="_blank" href="https://facebook.com/retroresume"><Facebook /></a>
                        </div>
                    </Grid>
                </div>
            </Drawer>
        </header>
    )
}

const mapStateToProps = ({ user }) => ({ user })

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ signOutUser }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
