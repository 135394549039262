import { app } from "./config";
import { getAnalytics } from "firebase/analytics";
import { collection, doc, query, where, getDocs, getFirestore, getDoc, setDoc, updateDoc} from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, GoogleAuthProvider, signInWithPopup, sendPasswordResetEmail } from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const db = getFirestore();
const auth = getAuth();
const storage = getStorage();
const provider = new GoogleAuthProvider();

export default {
    async signIn({ email, password }) {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        return { uid: user.uid, signUp: null };
    },

    async googleAuth({ email = '' }) {
        email = email.toLowerCase();
        const res = await signInWithPopup(auth, provider).then(async (result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            // The signed-in user info.
            const user = result.user;

            const payload = {
                email: user.email,
                phoneNumber: user.phoneNumber,
                photoURL: user.photoURL,
                displayName: user.displayName,
                uid: user.uid,
                emailVerified: user.emailVerified
            }
            
            const querySnapshot = await getDocs(query(collection(db, "users"), where("email", "==", user.email)));
            if(querySnapshot.empty) {
                await this.saveUserData(user.uid, payload);
                return { uid: user.uid, signUp: payload.displayName };
            } else {
                return { uid: user.uid, signUp: null }
            }
        })
        return res;
    },

    async signUp({ email, password, ...payload }) {
        return createUserWithEmailAndPassword(auth, email, password).then(async (userCredential) => {
            const user = userCredential.user;
            return this.saveUserData(user.uid, { email, ...payload });
        })
    },

    async signOut() {
        return signOut(auth);
    },

    async resetPassword(email) {
        await sendPasswordResetEmail(auth, email);
    },

    async getUserData(uid) {
        const docRef = doc(db, "users", uid);
        const docSnap = await getDoc(docRef);
        return Boolean(docSnap.exists()) ? { uid, ...docSnap.data() } : {};
    },

    async saveUserData(uid, payload) {
        const created = Date.now();
        await setDoc(doc(db, "users", uid), {
            created, accountStatus: 'active',
            ...payload
        });
    },

    async updateUserData(uid, payload) {
        const updated = Date.now();
        await updateDoc(doc(db, "users", uid), {
            updated,
            ...payload
        });
    },

    async updateUserImage(uid, image) {
        const imageRef = ref(storage, `users/${uid}/photoURL.jpg`);
        await uploadBytes(imageRef, image)
        const url = await getDownloadURL(imageRef)
        
        return updateDoc(doc(db, "users", uid), {
            photoURL: url
        });
    },
}