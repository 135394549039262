import AccountContainer from '../../components/Account/AccountContainer'
import { handleCart, handleWishList } from '../../redux/actions/serviceActions'
import { baseUrl } from '../../redux/api/config';
import { getPrice, toast } from '../../util';
import { Button, Divider, Grid } from '@material-ui/core'
import { DeleteForever } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useHistory } from 'react-router-dom';

const Wishlist = ({ user, services, handleCart, handleWishList }) => {
    const history = useHistory()
    const [cartList, setCartList ] = useState(services?.cartList || [])
    const [ wishList, setWishList ] = useState(services?.wishList || [])

    const addCart = async (template) => {
        if(cartList.filter(({ id }) => id === template.id).length === 0) {
            await handleCart(template, 'add')
            toast('Template added to cart')
        } else {
            history.push('/account/cart')
        }
    }

    const removeFromWishList = async (template) => {
        await handleWishList(template, 'remove')
        toast('Template removed from Wishlist')
    }

    useEffect(() => {
        setCartList(services?.cartList || [])
        setWishList(services?.wishList || [])
    }, [services])

    return (
        <AccountContainer title={`Wishlist (${wishList?.length || 0} item${wishList?.length === 1 ? '' : 's'})`}>
                        <Grid container>
                {
                    wishList.map((template, key) => (
                        <Grid container item xs={12} spacing={3} key={key}>
                            <Grid item xs={4} md={2}>
                                <img 
                                    src={baseUrl + `/generate-template-image?template=${template?.functionName}`}
                                    style={{ width: '100%', objectFit: 'cover' }}
                                />
                            </Grid>
                            <Grid item xs={8} md={10} container justifyContent='space-between' direction='row'>
                                <Grid item xs={12}>
                                    <h4 className='template-price text-left'>{getPrice(template?.price)}</h4>
                                    <p className='template-title text-left mt-0'>{template?.title || ''}</p>
                                </Grid>
                                <Grid item xs={12} container alignItems='flex-end'>
                                    <Button style={{ margin: '.5rem' }} onClick={() => addCart(template)}
                                        variant='contained' color='primary' className='text-capitalize'
                                    >
                                        {
                                            Boolean(cartList.filter(({ id }) => id === template.id).length === 0)?
                                            "Add to Cart" : "View cart"
                                        }
                                    </Button>

                                    <Button startIcon={<DeleteForever />} style={{ margin: '.5rem' }}
                                        variant='text' className='text-capitalize' onClick={() => removeFromWishList(template)}
                                    >Remove from wishlist</Button>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} className='mb-2'>
                                <Divider />
                            </Grid>
                        </Grid>
                    ))
                }
            </Grid>
        </AccountContainer>
    )
}

const mapStateToProps = ({ services, user }) => ({ services, user })

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ handleCart, handleWishList }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Wishlist)
